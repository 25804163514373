import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  
  updatedUserProfile:{},
 
};

const updateUserProfileSlice = createSlice({
  name: "updateUserProfileSlice",
  initialState,
  reducers: {
    
    setUpdatedUserProfile :(state,action) =>{
      state.updatedUserProfile = action.payload
    },
    
    
  },
});

export const {
  setUpdatedUserProfile
  } = updateUserProfileSlice.actions;
  
  export default updateUserProfileSlice.reducer;