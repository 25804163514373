import React, { useState } from "react";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";

const useShowPassword = () => {
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  function renderIcon() {
    return showPassword ? (
      <BsFillEyeFill
        className="icon"
        color="#999"
        onClick={handleShowPassword}
      />
    ) : (
      <BsFillEyeSlashFill
        className="icon"
        color="#999"
        onClick={handleShowPassword}
      />
    );
  }
  return { showPassword, handleShowPassword, renderIcon };
};

export default useShowPassword;
