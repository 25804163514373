import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  token: null,
  forgetPasswordToken: "",
  events: [],
  isMusicPlay : false
};

const authSlice = createSlice({
  name: "authUser",
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token || action.payload.userToken;
    },
    socialLoginSuccess: (state, action) => {
      state.user = action.payload;
      state.token = action.payload.token;
    },
    setEmailforOtp: (state, action) => {
      state.emailForOtp = action.payload;
    },
    setForgetPasswordToken: (state, action) => {
      state.forgetPasswordToken = action.payload;
    },
    userProfile: (state, action) => {
      state.user = action.payload;
    },
    eventList: (state, action) => {
      state.events = action.payload;
    },
    logout: (state) => {
      state.user = null;
      state.token = null;
    },
    getIsPlaying :(state,action) =>{
      state.isplayingData = action.payload
    },
    checkMusic :(state,action) =>{
      state.isMusicPlay = action.payload
    }
    
  },
});

export const {
  loginSuccess,
  setEmailforOtp,
  setForgetPasswordToken,
  socialLoginSuccess,
  eventList,
  logout,
  getIsPlaying,
  checkMusic
} = authSlice.actions;

export default authSlice.reducer;
