import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import reducer from '../reducers';
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["isPlayingData","check"],
};

const persistedReducer = persistReducer(persistConfig, reducer);

function logger({ getState }) {
  return (next) => (action) => {
    // Call the next dispatch method in the middleware chain.
    const returnValue = next(action);

    // This will likely be the action itself, unless
    // a middleware further in chain changed it.
    return returnValue;
  };
}

export const store = configureStore({
  reducer:persistedReducer,
  middleware: [thunk, logger],
});
 export const persistor = persistStore(store);
// export default persistor;
// export default store;