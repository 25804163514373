import React from "react";
import { toast } from "react-toastify";

const InviteModal = () => {
  const handleCopyLink = () => {
    const link = navigator.clipboard.writeText(`${window.location.host}/main`);
    toast.success("Link Copied!", link);
  };

  const handleFBShare = () => {
    window.open(
      "https://www.facebook.com/sharer/sharer.php?%27" +
        `${window.location.host}/main`
    );
  };

  const handleInstaShare = () => {
    window.open(
      "https://www.facebook.com/sharer/sharer.php?%27" +
        `${window.location.host}/main`
    );
  };
  const handleLinkedInShare = () => {
    window.open(
      `https://www.linkedin.com/sharing/share-offsite/?url=${window.location.host}/main`
    );
  };
  const handleTwitterShare = () => {
    window.open("https://twitter.com/share?" + `${window.location.host}/main`);
  };

  return (
    <div
      className="modal fade"
      id="invite-popup"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered rate-popup invite">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Invite Friends
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body d-dlex align-items-center">
            <div className="icon-bar align-items-center justify-content-center gap-3">
              {/* <a className="social-link" onClick={handleFBShare}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14.484"
                  height="27.043"
                  viewBox="0 0 14.484 27.043"
                >
                  <path
                    id="fb"
                    d="M15.144,15.212l.751-4.894H11.2V7.142A2.447,2.447,0,0,1,13.958,4.5h2.135V.331A26.035,26.035,0,0,0,12.3,0c-3.867,0-6.4,2.344-6.4,6.587v3.73h-4.3v4.894h4.3V27.043H11.2V15.212Z"
                    transform="translate(-1.609)"
                    fill="#395693"
                  />
                </svg>
              </a>
              <a className="social-link" onClick={handleInstaShare}>
                <svg
                  id="instagram_1"
                  data-name="instagram 1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="25.682"
                  height="25.682"
                  viewBox="0 0 25.682 25.682"
                >
                  <defs>
                    <linearGradient
                      id="linear-gradient"
                      x1="0.5"
                      y1="0.008"
                      x2="0.5"
                      y2="0.998"
                      gradientUnits="objectBoundingBox"
                    >
                      <stop offset="0" stopColor="#ffad3e" />
                      <stop offset="0.3" stopColor="#c74c4d" />
                      <stop offset="0.6" stopColor="#c21975" />
                      <stop offset="1" stopColor="#7024c4" />
                    </linearGradient>
                    <linearGradient
                      id="linear-gradient-2"
                      x1="0.5"
                      y1="-0.451"
                      x2="0.5"
                      y2="1.462"
                      gradientUnits="objectBoundingBox"
                    >
                      <stop offset="0" stopColor="#e09b3d" />
                      <stop offset="0.3" stopColor="#c74c4d" />
                      <stop offset="0.6" stopColor="#c21975" />
                      <stop offset="1" stopColor="#7024c4" />
                    </linearGradient>
                    <linearGradient
                      id="linear-gradient-3"
                      y1="-1.396"
                      y2="6.586"
                      xlinkHref="#linear-gradient-2"
                    />
                  </defs>
                  <path
                    id="Path_33903"
                    data-name="Path 33903"
                    d="M18.031,0H7.651A7.659,7.659,0,0,0,0,7.651v10.38a7.659,7.659,0,0,0,7.651,7.651h10.38a7.659,7.659,0,0,0,7.651-7.651V7.651A7.659,7.659,0,0,0,18.031,0ZM23.1,18.031A5.067,5.067,0,0,1,18.031,23.1H7.651a5.067,5.067,0,0,1-5.067-5.067V7.651A5.067,5.067,0,0,1,7.651,2.584h10.38A5.067,5.067,0,0,1,23.1,7.651v10.38Z"
                    fill="url(#linear-gradient)"
                  />
                  <path
                    id="Path_33904"
                    data-name="Path 33904"
                    d="M139.642,133a6.642,6.642,0,1,0,6.642,6.642A6.65,6.65,0,0,0,139.642,133Zm0,10.7a4.059,4.059,0,1,1,4.059-4.059A4.059,4.059,0,0,1,139.642,143.7Z"
                    transform="translate(-126.801 -126.801)"
                    fill="url(#linear-gradient-2)"
                  />
                  <circle
                    id="Ellipse_1538"
                    data-name="Ellipse 1538"
                    cx="1.592"
                    cy="1.592"
                    r="1.592"
                    transform="translate(17.904 4.657)"
                    fill="url(#linear-gradient-3)"
                  />
                </svg>
              </a>
              <a className="social-link" onClick={handleLinkedInShare}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24.877"
                  height="24.877"
                  viewBox="0 0 24.877 24.877"
                >
                  <path
                    id="Icon_awesome-linkedin-in"
                    data-name="Icon awesome-linkedin-in"
                    d="M5.569,24.877H.411V8.269H5.569ZM2.987,6A3,3,0,1,1,5.974,2.988,3.012,3.012,0,0,1,2.987,6ZM24.872,24.877H19.725V16.792c0-1.927-.039-4.4-2.682-4.4-2.682,0-3.092,2.093-3.092,4.259v8.224H8.8V8.269h4.947v2.266h.072A5.419,5.419,0,0,1,18.7,7.852c5.22,0,6.179,3.437,6.179,7.9v9.124Z"
                    transform="translate(0 -0.001)"
                    fill="#0077b5"
                  />
                </svg>
              </a> */}
              <a
                className="social-link"
                onClick={(e) => {
                  window.location.href = "mailto:no-reply@example.com";
                  e.preventDefault();
                }}
              >
                <svg
                  viewBox="0 0 512 352"
                  width="26.969"
                  height="26.969"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M339.392 178.624L512 287.744V64.896L339.392 178.624Z"
                    fill="black"
                  />
                  <path
                    d="M0 64.896V287.744L172.608 178.624L0 64.896Z"
                    fill="black"
                  />
                  <path
                    d="M480 0H32C16.032 0 3.35996 11.904 0.959961 27.232L256 195.264L511.04 27.232C508.64 11.904 495.968 0 480 0Z"
                    fill="black"
                  />
                  <path
                    d="M310.08 197.952L264.8 227.776C262.112 229.536 259.072 230.4 256 230.4C252.928 230.4 249.888 229.536 247.2 227.776L201.92 197.92L1.02405 324.992C3.48805 340.192 16.096 352 32 352H480C495.904 352 508.512 340.192 510.976 324.992L310.08 197.952Z"
                    fill="black"
                  />
                </svg>
              </a>
              <a onClick={handleCopyLink} className="social-link">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24.969"
                  height="24.969"
                  viewBox="0 0 24.969 24.969"
                >
                  <g id="link" transform="translate(-0.001 0)">
                    <path
                      id="Path_33905"
                      data-name="Path 33905"
                      d="M190.83,1.714l-5.075,5.075c-.01.01-.016.022-.026.031a7.334,7.334,0,0,1,3.725.407L192.9,3.783a2.926,2.926,0,0,1,4.138,4.138L191.962,13a2.957,2.957,0,0,1-4.138,0,1.465,1.465,0,0,0-2.069,0l-.888.888a5.8,5.8,0,0,0,.888,1.181,5.9,5.9,0,0,0,8.245.026c.01-.01.022-.016.031-.026l5.075-5.075a5.852,5.852,0,0,0-8.276-8.276Z"
                      transform="translate(-175.851 0)"
                      fill="#282828"
                    />
                    <path
                      id="Path_33906"
                      data-name="Path 33906"
                      d="M11.378,177.62l-3.456,3.456a2.926,2.926,0,1,1-4.138-4.138l5.086-5.086a2.958,2.958,0,0,1,4.138,0,1.465,1.465,0,0,0,2.069,0l.888-.888a5.8,5.8,0,0,0-.888-1.181,5.892,5.892,0,0,0-8.245-.026c-.01.01-.022.016-.031.026l-5.086,5.086a5.852,5.852,0,0,0,8.276,8.276l5.086-5.086c.01-.01.016-.022.026-.031a7.334,7.334,0,0,1-3.725-.407Z"
                      transform="translate(0 -159.889)"
                      fill="#282828"
                    />
                  </g>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InviteModal;
