import React, { useState } from "react";
import { useForm } from "react-hook-form";
import ReactStars from "react-rating-stars-component";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { submitReview } from "../../api/song";
import CommonInput from "../common/CommonInput";
import { Modal } from "bootstrap";
import PaymentModal from "./PaymentModal";
import UpgradeToPremium from "./UpgradeToPremium";

const FreeUserModal = () => {
  const [modalToShow, setModalToShow] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const { token } = useSelector((state) => state.authUser);

  // useForm hook
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();

  // submit rating

  return (
    <>
      <div className="rate-info mt-0 free-user-info">
        <div className="text-center pb-3">
          <svg
            id="crown"
            xmlns="http://www.w3.org/2000/svg"
            width="74.54"
            height="74.539"
            viewBox="0 0 74.54 74.539"
          >
            <path
              id="Path_33929"
              data-name="Path 33929"
              d="M74.539,37.27A37.27,37.27,0,1,1,37.27,0,37.269,37.269,0,0,1,74.539,37.27Z"
              fill="#e1a90f"
            />
            <path
              id="Path_33930"
              data-name="Path 33930"
              d="M267.716,320.485l-.751,4.471a1.46,1.46,0,0,1-1.427,1.119H236.255a1.46,1.46,0,0,1-1.427-1.119l-.751-4.471ZM250.842,294.4a1.457,1.457,0,0,1,1.209.558l9.413,11.936,7.652-5.031a1.484,1.484,0,0,1,1.645.013,1.441,1.441,0,0,1,.6,1.514l-2.967,13.371H233.4l-2.965-13.371a1.438,1.438,0,0,1,.553-1.485,1.481,1.481,0,0,1,1.6-.093l9.061,5.108,8.026-11.875a1.472,1.472,0,0,1,1.166-.646Z"
              transform="translate(-213.627 -272.966)"
              fill="#fff"
            />
          </svg>
        </div>
        <h4 className="text-center text-white">Your are a free user</h4>
        <p className="mb-4 text-secondary text-center">
          Switch to premium to enjoy streaming
        </p>

        <div className="d-flex justify-content-center mt-2">
          <a
            role={"button"}
            onClick={() => setModalShow(true)}
            href=""
            className="btn-rate"
            data-bs-toggle="modal"
            data-bs-target="#premium-popup"
          >
            Get Premium
          </a>
        </div>
      </div>

      <UpgradeToPremium />
    </>
  );
};

export default FreeUserModal;
