
import React, { useEffect, useRef } from "react";

const Global = ({yourAudioRef}) => {

  return (
    <div className="audio-player mb-5" style={{ margin: "0 auto", display:"none"}}>
      <audio id="yourAudio" ref={yourAudioRef}  controls preload="none">
        <source src="https://azuracast.cfader.com:8000/radio.mp3" type="audio/mp3" />
      </audio>
    </div>
  );
};


export default Global;


