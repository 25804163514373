import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  createFreeSubscription,
  fileUpload,
  fileUploadAfterResponse,
  userSignup,
} from "../../api/user";
import {
  EnvelopeSvg,
  LockSvg,
  PhoneIconSvg,
  RadioLogo,
  TopLogo,
  UserIconSvg,
} from "../../assets/images";
import { convertFileIntoUrl, getFromLocalStorage } from "../../helpers";
import useShowPassword from "../../hooks/useShowPassword";
// import "../../assets/css/admin-web.css"
import countries from "../../utils/country.json";

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

const schema = yup.object({
  email: yup.string().email("Please enter a valid Email").required('Email is required'),
  mobile: yup.string().transform((value,originalValue)=>originalValue ? originalValue.replace(/-/g, ''):'').required('Mobile is required').min(10, 'Mobile must be at least 10 characters long').max(10, 'Mobile must be less than 10 characters long'),
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  password: yup.string().required('Password is required'),
  confirmPassword: yup.string().required('Confirm Password is required').test('passwords-match', 'Passwords must match', function(value) {
    return this.parent.password === value;
  }
  ),
}).required('All fields are required');


const Signup = () => {
  const navigate = useNavigate();
  const inputFile = useRef(null);
  const dispatch = useDispatch();
  const [src, setSrc] = useState("");
  const [srcUrl, setSrcUrl] = useState("");
  const [selectedCountry, setSelectedCountry] = useState();
  const [isAgreeWithTNC, setIsAgreeWithTNC] = useState(false);
  const [loading,setLoading] = useState(false)

  const { showPassword, renderIcon } = useShowPassword();

  const uploadToAWs = (file, setState, key, state) => {
    dispatch(fileUpload(file))
      .then((res) => {
        if (res.statusCode === 200) {
          if (key === "video") {
            let urlArr = [...state];
            urlArr.push({ file: res.data.fileName, title: "video" });
            setState(urlArr);
          } else {
            setState(res.data.fileName);
          }
          dispatch(fileUploadAfterResponse(res.data.preSignedUrl, file));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  useEffect(() => {
    const token = getFromLocalStorage("token");
    if (Object.keys(token).length) {
      navigate("/");
    }
  }, [navigate]);

  const onFileChange = (e) => {
    const fileObj = e.target.files[0];

    if (fileObj) {
      if (fileObj.type === "image/png" || fileObj.type === "image/jpeg") {
        convertFileIntoUrl(fileObj, setSrc);
        uploadToAWs(fileObj, setSrcUrl, "image");
      }
    }
  };

  const onButtonClick = () => {
    inputFile.current.click();
  };

  //react-hook-form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = (data) => {
    setLoading(true)
    Object.assign(
      data,
      { profileImage: srcUrl },
      { subscriptionTypeSelection: "free" }
    );
    dispatch(userSignup(data))
      .then((res) => {
        setLoading(false)
        if (res.statusCode === 200) {
          navigate("/user-verification", {
            state: {
              token: res.data.userToken,
              mobile: data.mobile,
              countryCode: data.mobileCountryCode || "+91",
            },
          });
          // dispatch(createFreeSubscription())
          // .then((res) => navigate("/main"))
          //   .catch((err) => toast.error("Signup Successful"));
          // toast.success("Signup Successful, use 1234 as an OTP.");
        }
      })
      .catch((error) =>
        {
          setLoading(false)
          toast.error(error?.message || "Something went wrong")}
      );
  };
  return (
    <div className="bg-black">
      <section id="home" className="mb-0">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 main_menu">
              <nav
                id="navbar"
                className="navbar navbar-expand-lg navbar-light bg-none custom_navbar py-3"
              >
                <div className="container-fluid px-0">
                  <a
                    className="navbar-brand"
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/");
                    }}
                  >
                    <img src={TopLogo} alt="Logo" />
                  </a>
                  <button
                    className="navbar-toggler "
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section id="sign-up-new">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-sm-12 col-md-7 col-lg-5">
              <div className="login-mains">
                <div className="logo-main">
                  <img
                    src={src || RadioLogo}
                    className="signup-user-profile"
                    role="button"
                    onClick={onButtonClick}
                  />
                  <input
                    type="file"
                    id="file"
                    ref={inputFile}
                    style={{ display: "none" }}
                    onChange={onFileChange}
                  />
                </div>
                <div className="card shadow py-4 px-4">
                  <div className="heading-logo pt-5 mb-4">
                    <h4>Sign up</h4>
                  </div>
                  <div className="sign-form">
                    <form>
                      <div className="mb-4 input_ps">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Email Address"
                          {...register("email")}
                        />
                        <img src={EnvelopeSvg} />
                          <span className="text-danger">
                           {errors?.email?.message}
                          </span>
                      </div>
                      <div className="row g-3">
                        <div className="col-3 input_ps">
                          <select
                            className="form-select mb-3 form-control"
                            name="mobileCountryCode"
                            {...register("mobileCountryCode")}
                          >
                            {countries &&
                              countries.map((country) => (
                                <option
                                  key={country.name}
                                  value={country.dial_code}
                                >
                                  {country.code} {country.dial_code}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="col-9 input_ps">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Phone Number"
                            name="mobile"
                            {...register("mobile")}
                          />
                          <img src={PhoneIconSvg} />
                         
                            <span className="text-danger">
                            {errors?.mobile?.message}
                            </span>
                        </div>
                      </div>
                      <div className="mb-4 input_ps">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="First Name"
                          name="firstName"
                          {...register("firstName")}
                        />
                        <img src={UserIconSvg} />
                          <span className="text-danger">
                            {errors?.firstName?.message}
                          </span>
                        
                      </div>
                      <div className="mb-4 input_ps">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Last Name"
                          name="lastName"
                          {...register("lastName")}
                        />
                        <img src={UserIconSvg} />
                     
                          <span className="text-danger">
                            {errors?.lastName?.message}
                          </span>
                    
                      </div>
                      <div className="mb-4 input_ps">
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control"
                          placeholder="Password"
                          name="password"
                          {...register("password")}
                        />
                        {/* <img src={LockSvg} /> */}
                        {renderIcon()}
                          <span className="text-danger">
                            {errors?.password?.message}
                          </span>
                        
                      </div>
                      <div className="mb-4 input_ps">
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control"
                          placeholder="Confirm Password"
                          name="confirmPassword"
                          {...register("confirmPassword")}
                        />
                        {renderIcon()}
                          <span className="text-danger">
                            {errors?.confirmPassword?.message}
                          </span>
                        
                      </div>
                    </form>
                  </div>
                  <div className="content_sign text-center">
                    <a href="#" className="text-sign-mains">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          onChange={(e) => setIsAgreeWithTNC(e.target.checked)}
                          // value={isAgreeWithTNC}
                          id="isAgreeWithTNC"
                          name="isAgreeWithTNC"
                          {...register("isAgreeWithTNC")}
                        />
                        Accept all the <span>Terms of Services </span> and
                        <span> Privacy Policy </span>
                      </div>
                    </a>
                    <a
                      role={"button"}
                      className="main_btn"
                      onClick={handleSubmit(onSubmit)}
                    >
                     {
                          loading ? <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : "Next"
                        }
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Signup;
