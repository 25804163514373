import React, { useEffect, useState } from "react";
import {
  EnvelopeSvg,
  PhoneIconSvg,
  RadioLogo,
  TopLogo,
} from "../../assets/images/index";
import "../../assets/css/admin-web.css";
import "../../assets/css/style.css";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  userLoginUsingEmail,
  userLoginUsingMobile,
} from "../../api/user";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import country from "../../utils/country.json";
import { getFromLocalStorage } from "../../helpers";
import CommonInput from "../../components/common/CommonInput";
import useShowPassword from "../../hooks/useShowPassword";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

// const schema = yup.object({
//   email: yup.string().email("Please enter a valid Email").required('Email is required'),
//   password: yup.string().required('Password is required'),
// }).required();
//
//


const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();


  const [loginType, setLoginType] = useState("email");
  const [selectedOption, setSelectedOption] = useState(country[0].dial_code);
  const { showPassword, renderIcon } = useShowPassword();
  const [loading,setLoading] = useState(false)

  useEffect(() => {
    if (getFromLocalStorage("token")) {
      navigate("/main");
    }
  }, []);

  const handleChecked = (e) => {
    setLoginType("email");
    reset({
      email: "",
      password: "",
    });
  };
  const onSubmit = (data) => {
    setLoading(true)
    const apiData = {
      email: data.email,
      password: data.password,
      deviceId: 213454,
    };
    const mobile = loginType !== "email" && data.phone.replace(/-/g, '');
    const apiDataForMobileLogin = {
      mobile,
      mobileCountryCode: selectedOption || "+91",
      deviceId: "hghgdd",
    };

    if (loginType === "email") {
      dispatch(userLoginUsingEmail(apiData))
        .then((res) => {
          setLoading(false)
          if (res.statusCode === 200) {
            navigate("/main");
          } else {
            setLoading(false)
            toast.error("something went wrong");
          }
        })
        .catch((err) => {
          setLoading(false)
          toast.error(err.message || "something went wrong");
        });
    } else {
      dispatch(userLoginUsingMobile(apiDataForMobileLogin))
        .then((res) => {
          setLoading(false)
          if (res.statusCode === 200) {
            navigate("/user-verification", {
              state: {
                mobile: apiDataForMobileLogin.mobile,
                countryCode: selectedOption || "+91",
              },
            });
          } else {
              setLoading(false)
            toast.error("something went wrong");
          }
        })
        .catch((err) => {
          setLoading(false)
          toast.error(err.message);
        });
    }
  };

  const changeLoginType = () => {
    if (loginType === "email") {
      return (
        <>
          <div className="mb-4 input_ps">
            <CommonInput
              type={"text"}
              className={"form-control"}
              placeholder={"Email Address"}
              name={"email"}
              register={register}
            />
            <img src={EnvelopeSvg} />
            {errors?.email && (
              <p className="text-danger">Email is required</p>
            )}
          </div>
          <div className="mb-4 input_ps">
            <CommonInput
              type={showPassword ? "text" : "password"}
              className={"form-control"}
              placeholder={"Password"}
              name={"password"}
              register={register}
            />
            {renderIcon()}
            
            {errors?.password && (
              <p className="text-danger">Password is required</p>
            )}
            
          </div>
        </>
      );
    } else {
      return (
        <div className="row g-3">
          <div className="col-2 input_ps">
            <select
              className="form-select"
              onChange={(e) => setSelectedOption(e.target.value)}
            >
              {country.map((v, i) => (
                <option
                  key={i}
                  value={v.dial_code}
                >
                  {v.dial_code}
                </option>
              ))}
            </select>
          </div>
          <div className="col-10 input_ps">
            <CommonInput
              type={"text"}
              className={"form-control"}
              placeholder={"Phone Number"}
              name={"phone"}
              register={register}
            />
            <img src={PhoneIconSvg} />
            {errors?.phone && (
              <p className="text-danger">Phone is required</p>
            )}
          </div>
        </div>
      );
    }
  };
  return (
    <div className="bg-black">
      <section id="home">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 main_menu">
              <nav
                id="navbar"
                className="navbar navbar-expand-lg navbar-light bg-none custom_navbar py-3"
              >
                <div className="container-fluid px-0">
                  <Link to={"/"} className="navbar-brand" href="">
                    <img src={TopLogo} alt="Logo" />
                  </Link>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section id="sign-up">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-sm-12 col-md-7 col-lg-5">
              <div className="login-mains">
                <div className="logo-main">
                  <img src={RadioLogo} />
                </div>

                <div className="card shadow py-4 px-4">
                  <form >
                    <div className="heading-logo pt-5">
                      <h4>Welcome to Cfader</h4>
                      <p>
                        {" "}
                        Please sign into your account with either your email
                        address or phone number and the corresponding password
                      </p>
                    </div>
                    <div className="sign-form">
                      <div className="mb-4">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault2"
                            checked={loginType === "email" ? true : false}
                            onChange={() => {
                              setLoginType("email");
                              reset({
                                email: "",
                                password: "",
                              });
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault2"
                          >
                            Email Address
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            checked={loginType !== "email" ? true : false}
                            onChange={() => {
                              setLoginType("phone");
                              reset({
                                phone: "",
                              });
                            }}
                          />

                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault1"
                          >
                            Phone Number
                          </label>
                        </div>
                      </div>
                      {changeLoginType()}
                    </div>
                    <div className="content_sign text-center">
                      {loginType === "email" && (
                        <Link
                          to={"/forgot_password"}
                          href=""
                          className="text-sign"
                        >
                          Forgot Email or Password
                        </Link>
                      )}
                      <a
                        role={"button"}
                        type="submit"
                        className="main_btn"
                        onClick={handleSubmit(onSubmit)}
                      >
                        {
                          loading ? <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : "Login"
                        }
                      </a>
                      <a
                        onClick={() => navigate("/signup")}
                        className="text-sign-main mt-4"
                        role={"button"}
                      >
                        Create New Account
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Login;
