import React from "react";
import { useSelector } from "react-redux";
import About from "../../components/About/About";
import Download from "../../components/Download/Download";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Subscribe from "../../components/Subscribe/Subscribe";

const Home = () => {
  const { user } = useSelector((state) => state.authUser);
  return (
    <>
      <Header />
      <About />
      {user === null && <Subscribe />}
      <Download />
      <Footer />
    </>
  );
};

export default Home;
