import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getUserProfile } from "../../api/user";
import { ArrowLeft, EditIcon, MyProfileImg } from "../../assets/images";
import Wrapper from "../Main/Wrapper";
import { LazyLoadImage } from "react-lazy-load-image-component";

const MyProfile = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authUser);

  useEffect(() => {
    dispatch(getUserProfile());
  }, []);

  return (
    <Wrapper>
      <section id="sign-up">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-sm-12 col-md-7 col-lg-5">
              <div className="about">
                <div className="d-flex mb-4">
                  <Link to={"/settings"}>
                    <img src={ArrowLeft} />
                  </Link>
                  <h1 className="mx-3 mb-2">My Profile</h1>
                </div>
                <div className="sign-form mt-10">
                  <div className="info profile">
                    <div className="edit_profile">
                      <LazyLoadImage
                        className="image--cover"
                        alt={"User Profile"}
                        src={user?.profileImage || MyProfileImg} // use normal <img> attributes as props
                      />
                      {/* <span>{image.caption}</span> */}
                      {/* <img
                        src={user?.profileImage || MyProfileImg}
                        alt=""
                        className="image--cover"
                      /> */}
                    </div>
                    <div className="d-flex justify-content-between mt-5">
                      <div className="name_info">
                        <h5> First Name</h5>
                        <span>{user?.firstName}</span>
                      </div>
                      <div className="name_info">
                        <h5> Last name</h5>
                        <span>{user?.lastName}</span>
                      </div>
                    </div>
                    <div className="name_info">
                      <h5> Email Address</h5>
                      <span>{user?.email}</span>
                    </div>
                    <div className="name_info">
                      <h5>Phone Number</h5>
                      <span>
                        {user?.mobileCountryCode}
                        {user?.mobile}
                      </span>
                    </div>
                    <div className="text-center">
                      <Link
                        to={"/edit-profile"}
                        className="main_btn mx-auto text-center mt-3"
                      >
                        <img src={EditIcon} className="me-3" />
                        Edit Profile
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Wrapper>
  );
};

export default MyProfile;
