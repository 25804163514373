import React from "react";
import { useNavigate } from "react-router-dom";
import MobileImg from "../../assets/images/banner-mobileimg.png";
import { getFromLocalStorage } from "../../helpers";
const HeroComponent = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (getFromLocalStorage("cfaderToken")) {
      navigate("/main");
    } else {
      navigate("/demo");
    }
  };

  return (
    <div className="row mt-5 mt-sm-0 py-5">
      <div className="col-sm-12 col-md-6 d-flex align-items-center justify-content-center">
        <div className="left_side">
          <h1>Welcome to Cfader Radio, </h1>
          <span> House Music finally has a Home!</span>
          <a
            role={"button"}
            onClick={handleClick}
            className="login_btn "
            href=""
            data-bs-toggle="modal"
            data-bs-target="#video-popup"
          >
            Listen Now
          </a>
        </div>
      </div>
      <div className="col-sm-12 col-md-6 text-xxl-end">
        <img
          data-aos="zoom-in"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-anchor-placement="top-center"
          src={MobileImg}
          alt=""
          className="img-fluid"
        />
      </div>
    </div>
  );
};

export default HeroComponent;
