import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ArrowLeft } from "../../assets/images";
import Wrapper from "../Main/Wrapper";
import CheckoutForm from "../../pages/SignupForPremium/CheckoutForm";
import { getFromLocalStorage } from "../../helpers";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../reducers/userSlice";
import { generateClientSecret } from "../../api/user";


const UpgradeSubscription = () => {
  const [clientSecret, setClientSecret] = useState("");
  const [loader,setLoader] = useState(false)
  const dispatch = useDispatch()

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
  const generatedClientSecret = async () => {
    setLoader(true)
    dispatch(generateClientSecret()).then((data) => {
      setLoader(false)
      // return  console.log(data)
      setClientSecret(data?.data?.clientSecret);
    }).catch((err)=>{
      setLoader(false)
      toast.error(err.message);
    }) 
  };

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };
  return (
    <div>
      <Wrapper>
        <section
          id="sign-up-new"
          style={{
            height: "calc(100vh - 50px)",
          }}
        >
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-sm-12 col-md-7 col-lg-5">
                <div className="abouts">
                  <div className="d-flex align-items-center">
                    <Link to={"/settings"}>
                      <img src={ArrowLeft} />
                    </Link>
                    <h1 className="mx-3 mb-3">Upgrade Subscription</h1>
                  </div>
                  <div className="info-p mt-4 w-100 d-flex justify-content-center">
                  {clientSecret ? (
                      <Elements stripe={stripePromise} options={options}>
                      <CheckoutForm />
                    </Elements>
                  ) :  loader ? <div class="spinner-border text-light" role="status">
                  {/* <span class="visually-hidden">Loading...</span> */}
                </div>  : 
                  <button
                  className="btn btn-danger rounded-pill w-12"
                  onClick={() => generatedClientSecret()}
                >
                  Make payment
                </button>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Wrapper>
    </div>
  );
};

export default UpgradeSubscription;
