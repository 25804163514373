import { getMixes, getPlaylist } from "../reducers/songSlice";
import api from "./index";

// Get Song list
// export const getSongList = () => async (dispatch) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const res = await api.get("/song/list");
//       dispatch(songList(res.data.data.data));
//       resolve(res.data.data.data);
//     } catch (err) {
//       reject(err.data);
//     }
//   });
// };
// Get Song Stream
export const getSongStream = () => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get("/song/playlist-url");
      // dispatch(songList(res.data.data.data));
      resolve(res.data.data.song);
      dispatch(getPlaylist(res.data.data.song));
    } catch (err) {
      reject(err.data);
    }
  });
};
export const getAds = () => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get("/file/next-ads");
      resolve(res.data)
    } catch (err) {
      reject(err.data);
    }
  });
};

// Create stream mixing song
// export const createSongMix = (apiData) => async (dispatch) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const res = await api.post("/song/stream-mix", apiData);
//       dispatch(getMixes(res.data.data.mixes));
//       resolve(res.data);
//     } catch (err) {
//       reject(err.data);
//     }
//   });
// };

// get all mixes
// export const getAllMixes = () => async (dispatch) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const res = await api.get("/song/get-all-mixes");
//       dispatch(songList(res.data.data));
//       resolve(res.data);
//     } catch (err) {
//       reject(err.data);
//     }
//   });
// };
export const getAllMixes = () => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get("/mix/list");
      dispatch(getMixes(res.data.data));
      resolve(res.data);
    } catch (err) {
      reject(err.data);
    }
  });
};

// Give rating to song
export const submitReview = (apiData) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post("/review/submit-review", apiData);
      resolve(res.data);
    } catch (err) {
      reject(err.data);
    }
  });
};

// Get Current Playing http://34.238.250.121/api/nowplaying

export const getCurrentPlaying = () => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get("https://azuracast.cfader.com/api/nowplaying");
      resolve(res.data);
    } catch (err) {
      reject(err);
    }
  });
};