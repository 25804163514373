import React, { useEffect } from "react";
import "../../assets/css/aos.css";
import "../../assets/css/admin-web.css";
import { InviteIcon, LogoRadio } from "../../assets/images";
// import { useNavigate } from "react-router-dom";
import Wrapper from "./Wrapper";
import FooterComp from "./FooterComp";
// import { getFromLocalStorage } from "../../helpers";
// import PlayDemoModal from "../../components/Modals/PlayDemoModal";
// import RatingModal from "../../components/Modals/RatingModal";
import InviteModal from "../../components/Modals/InviteModal";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import {  getCurrentPlaying, getSongStream } from "../../api/song";
import { toast } from "react-toastify";

import Global from "../../components/GlobalAudio/Global";

import Audio from "../../components/Player/Audio";

var jsmediatags = window.jsmediatags;

const Main = ({isPlaying,musicPlay,musicPause}) => {

  
  const { user } = useSelector((state) => state.authUser);
  const { mixList } = useSelector((state) => state.songs);

  const dispatch = useDispatch();

  const [playlistDetails, setPlaylistDetails] = useState(null);
  const [playlistImage, setPlaylistImage] = useState("");

  const [delay, setDelay] = useState(null)

  



  const getCurrent = () => {
    dispatch(getCurrentPlaying())
      .then((res) => {
        setDelay(res[0].now_playing.remaining * 1000)
        setPlaylistImage(res[0].now_playing.song.art);
        setPlaylistDetails(res[0].now_playing.song);
      })
      .catch((err) => toast.error(err));
  };
  useEffect(() => {
    if (!Object.keys(mixList).length) {
     if (delay) {
      setInterval(() => {
        getCurrent()
      }, delay);
     } else {
      getCurrent()
     }
    }
  }, [mixList, delay]);

  useEffect(() => {
    dispatch(getSongStream()).then((res) => {});
  }, []);

  useEffect(() => {
    if (Object.keys(mixList).length) {
      jsmediatags.read(mixList[0], {
        onSuccess: function (tag) {
          setPlaylistDetails(tag.tags);
          const { data } = tag.tags.picture;
          let base64String = "";
          for (var i = 0; i < data.length; i++) {
            base64String += String.fromCharCode(data[i]);
          }
          const url = `data:${data.format};base64,${window.btoa(base64String)}`;
          setPlaylistImage(url);
          var audio = document.getElementById("audio"),
            i = 0;
          audio.addEventListener(
            "ended",
            function () {
              i = ++i < mixList.length ? i : 0;
              audio.src = mixList[i];
              jsmediatags.read(mixList[i], {
                onSuccess: function (tag) {
                  setPlaylistDetails(tag.tags);
                  const { data } = tag.tags.picture;
                  let base64String = "";
                  for (var i = 0; i < data.length; i++) {
                    base64String += String.fromCharCode(data[i]);
                  }
                  const url = `data:${data.format};base64,${window.btoa(
                    base64String
                  )}`;
                  setPlaylistImage(url);
                  audio.play();
                },
                onError: function (error) {
                  console.log(error);
                },
              });
            },
            true
          );
          audio.src = mixList[0];
          audio.load();
          audio.play();
        },
        onError: function (error) {
          console.log(error);
        },
      });
    }
  }, [mixList]);

  return (
    <Wrapper>
      <section style={{ backgroundColor: "#111111" }}>
        <div className="container ">
          <div className="row">
            <div className="col-md-12">
              <div className="custom-name-box">
              <h5 className="text-white mb-0 w-auto">Welcome {user?.firstName}! {user?.subscription?.type === "premium" || user?.subscription.type === "freeTrial"  ? <span className="btn-prem">Premium</span> : null }  </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="sign-up-new">
        <div className="container">
          <div className="row align-items-center justify-content-center ">
            <div className="col-sm-12 col-md-7 col-lg-4">
              <div className="home-main mt-5 pt-5">
                <div className="radio_logos">
                  <img
                    src={playlistImage || LogoRadio}
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="description-rating d-flex justify-content-between mt-5">
                  <div className="ratings">
                    <h1>{playlistDetails?.title}</h1>
                  </div>
                  <div className="btn_rating">
                    <img src={InviteIcon} alt="" />
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#invite-popup"
                    >
                      Invite Friends
                    </a>
                  </div>
                </div>
                <Global />

                {!Object.keys(mixList).length ? (
                  isPlaying ? (
                    <div className="play-btn" onClick={musicPause}>
                      <i
                        className="fa fa-pause"
                        style={{
                          color: "#5c5a5a",
                          fontSize: "2rem",
                        }}
                      ></i>
                    </div>
                  ) : (
                    <div className="play-btn" onClick={musicPlay}>
                      <i
                        className="fa fa-play"
                        style={{
                          color: "#fff",
                          fontSize: "2rem",
                        }}
                      ></i>
                    </div>
                  )
                ) : null}
                {Object.keys(mixList).length ? (
                  <>
                    <Audio />
                    <div className="w-100 d-flex justify-content-center">
                      <div className="btn_rating col-lg-3 ">
                        <a role="button" onClick={()=>musicPlay()}>
                          Live Radio
                        </a>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
          <FooterComp name={"main"} />
        </div>
      </section>

      <InviteModal />
    </Wrapper>
  );
};

export default Main;


