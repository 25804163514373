import React from 'react';
import {useSelector} from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import SubscriptionBox from './SubscriptionBox';

const Subscribe = () => {
  const {user} = useSelector(state => state.authUser);

  const navigate = useNavigate();
  return (
    <section id="subscribe">
      <div className="container">
        <div className="row">
          <div className="col-md-12 mx-auto">
            <div className="heading-sub text-center">
              <h2>OUR SUBSCRIPTION PLANS</h2>
            </div>
          </div>
        </div>
        <div className="row d-flex justify-content-center mt-5">
          <div className="col-lg-4 col-md-12 mb-4">
            <SubscriptionBox
              planName="Free Plan"
              cardRequired="No credit card required"
              commercials="Commercials"
              soundQuality="Lower Sound Quality"
              mixesAccess="No Access to Exclusive Cfader Radio Mixes"
              deviceAtATime="Accessible by one (1) Device at a time"
              subscription="Free 14-Day Trial of Premium Plan described below"
              selected={'2px solid #de1b22'}
            />
            <div className="text-center mt-4">
              <Link className="sign_up_plan" to={'/signup'}>
                SIGN UP FOR FREE PLAN
              </Link>
            </div>
          </div>

          <div className="col-lg-4 col-md-12 mb-4">
            <SubscriptionBox
              planName="Premium Plan"
              price="$6.99/month"
              cardRequired="No Commercials"
              commercials="Premium Sound Quality"
              soundQuality="Access to All Cfader Mixes On-Demand"
              deviceAtATime="Accessible by three (3) Device at a time"
              subscription=" Create and Customize Your Own Play Lists"
              selected={'2px solid #de1b22'}
            />

            <div className="text-center mt-4">
              <a
                role={'button'}
                onClick={() =>
                  user?.subscription?.type === 'free'
                    ? navigate('/upgrade-subscription')
                    : navigate('/signup_premium')
                }
                className="sign_up_plan"
                href=""
                data-bs-toggle="modal"
                data-bs-target="#payment_popup_rem">
                {user?.subscription?.type !== 'free'
                  ? 'SIGN UP FOR PREMIUM PLAN'
                  : 'UPGRADE TO PREMIUM PLAN'}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Subscribe;
