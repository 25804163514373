import React from 'react'
import { SuccussIcon } from '../../assets/images'

const PaymentSuccessModal = () => {
  return (
    <div
    className="modal fade"
    id="success_popup"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabIndex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header">
          <a role={'button'}
            className="btn-close"
            aria-label="Close"
            data-bs-dismiss="modal"
          ></a>
        </div>
        <div className="succes_logo text-center mt-3">
          <img src={SuccussIcon} className="mx-auto p-4" />
        </div>
        <div className="heading-logo text-center">
          <h4 className="success_text">Successful!</h4>
        </div>
        <div className="modal-body mb-4">
          <div className="success_text w-75 mx-auto text-center">
            <p>
              Your payment has been successfully processed. You are now a
              Premium member and have all of the Premium features.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default PaymentSuccessModal