import { faCoffee } from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const PlayDemoModal = () => {
  return (
    <div
      className="modal fade"
      id="video-popup"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i
                className="fa fa-window-close"
                style={{
                  color: "#fff",
                }}
              ></i>
              <FontAwesomeIcon icon={faCoffee} />
            </button>
          </div>
          <div className="modal-body p-0">
            <iframe
              width="500"
              height="350"
              src="https://club-house.s3.amazonaws.com/Promo+Video+(2).mp4"
              poster="images/poster.jpg"
              controls
              allow="autoplay"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlayDemoModal;
