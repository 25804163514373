import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { changeUserPassword } from "../../api/user";
import { ArrowLeft, LockSvg, UserIconSvg } from "../../assets/images";
import { removeAllFromLocalStorage } from "../../helpers";
import useShowPassword from "../../hooks/useShowPassword";
import Wrapper from "../Main/Wrapper";

const ChangePassword = ({globalRef,isPlaying,setIsPlaying,musicPlay,musicPause,timeoutId,innerVal}) => {
  const {check} = useSelector(state=>state.check) 
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showPassword, renderIcon } = useShowPassword();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const apiData = {
      currentPassword: data.currentPassword,
      newPassword: data.newPassword,
      confirmPassword: data.confirmPassword,
    };
    if (data.currentPassword === data.newPassword) {
      toast.error("Current password and new password should not be same");
    } else if (data.newPassword !== data.confirmPassword) {
      toast.error("Password and confirm password does not match");
    } else {
      dispatch(changeUserPassword(apiData))
        .then((res) => {
          if (res.statusCode === 200) {
            toast.success(res.message);
            removeAllFromLocalStorage()
            navigate("/");
          } else {
            toast.error("something went wrong");
          }
        })
        .catch((err) => toast.error(err.message));
    }
  };

  return (
    <Wrapper>
      <section id="sign-up">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-sm-12 col-md-7 col-lg-5">
              <div className="about">
                <div className="d-flex mb-4">
                  <Link to={"/settings"}>
                    <img src={ArrowLeft} />
                  </Link>
                  <h1 className="mx-3 mb-3">Change Password</h1>
                </div>
                <form className="conatct_fm">
                  <div className="mb-4 input_ps">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control"
                      placeholder="Current Password"
                      name="currentPassword"
                      {...register("currentPassword", { required: true })}
                    />
                    {renderIcon()}
                    {errors.currentPassword && (
                      <p className="text-danger">This field is required</p>
                    )}
                  </div>
                  <div className="mb-4 input_ps">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control"
                      placeholder="New Password"
                      name="newPassword"
                      {...register("newPassword", { required: true })}
                    />
                    {renderIcon()}
                    {errors.newPassword && (
                      <p className="text-danger">This field is required</p>
                    )}
                  </div>
                  <div className="mb-4 input_ps">
                    <input
                      type={showPassword ? "text" : "password"} /*  */
                      className="form-control"
                      placeholder="Confirm Password"
                      name="confirmPassword"
                      {...register("confirmPassword", { required: true })}
                    />
                    {renderIcon()}
                    {errors.confirmPassword && (
                      <p className="text-danger">This field is required</p>
                    )}
                  </div>

                  <div className="text-center">
                    <a
                      role={"button"}
                      className="main_btn mx-auto text-center mt-3"
                      onClick={handleSubmit(onSubmit)}
                    >
                      Submit
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Wrapper>
  );
};

export default ChangePassword;
