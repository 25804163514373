import React, {useEffect, useRef, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import {
  editUserProfile,
  fileUpload,
  fileUploadAfterResponse,
} from '../../api/user';
import {
  ArrowLeft,
  EnvelopeSvg,
  MyProfileImg,
  PhoneIconSvg,
  UserIconSvg,
} from '../../assets/images';
import Wrapper from '../Main/Wrapper';
import CountryCode from '../../utils/country.json';
import {toast} from 'react-toastify';
import {convertFileIntoUrl} from '../../helpers';

const EditProfile = ({globalRef,isPlaying,setIsPlaying,musicPlay,musicPause,timeoutId,innerVal}) => {
  const {user} = useSelector(state => state.authUser);
  const {check} = useSelector(state => state.check);
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState(CountryCode[0].value);
  const navigate = useNavigate();
  const inputFile = useRef(null);
  const [src, setSrc] = useState('');
  const [srcUrl, setSrcUrl] = useState('');


  const uploadToAWs = (file, setState, key, state) => {
    dispatch(fileUpload(file))
      .then(res => {
        if (res.statusCode === 200) {
          if (key === 'video') {
            let urlArr = [...state];
            urlArr.push({file: res.data.fileName, title: 'video'});
            setState(urlArr);
          } else {
            setState(res.data.fileName);
          }
          dispatch(fileUploadAfterResponse(res.data.preSignedUrl, file));
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
  const onFileChange = e => {
    const fileObj = e.target.files[0];

    if (fileObj) {
      if (fileObj.type === 'image/png' || fileObj.type === 'image/jpeg') {
        convertFileIntoUrl(fileObj, setSrc);
        uploadToAWs(fileObj, setSrcUrl, 'image');
      }
    }
  };

  const onButtonClick = () => {
    inputFile.current.click();
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm({
    defaultValues: {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      mobile: user.mobile,
      mobileCountryCode: user.mobileCountryCode,
      profileImage: user.profileImage,
    },
  });

  const onSubmit = data => {
    const apiData = {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      mobile: data.mobile,
      mobileCountryCode: selectedOption || '+91',
      profileImage: srcUrl || user.profileImage,
    };
    dispatch(editUserProfile(apiData))
      .then(res => {
        if (res.statusCode === 200) {
          toast.success(res.message);
          navigate('/my-profile');
        } else {
          toast.error('something went wrong');
        }
      })
      .catch(err => console.log(err));
  };

  return (
    <Wrapper>
      <section id="sign-up-new">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-sm-12 col-md-7 col-lg-5">
              <div className="about">
                <div className="d-flex mb-4">
                  <Link to={'/my-profile'}>
                    <img src={ArrowLeft} />
                  </Link>
                  <h1 className="mx-3 mb-2">Edit Profile</h1>
                </div>
                <form className="conatct_fm">
                  <div className="profile-new">
                    <div className="text-center mb-3">
                      <input
                        type="file"
                        id="file"
                        ref={inputFile}
                        style={{display: 'none'}}
                        onChange={onFileChange}
                      />
                      <img
                        src={src || user.profileImage || MyProfileImg}
                        onClick={onButtonClick}
                        alt=""
                        className="image--cover"
                      />
                    </div>
                    <div className="mb-4 input_ps">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email Address"
                        name="email"
                        disabled
                        {...register('email', {required: true})}
                      />
                      <img src={EnvelopeSvg} />
                      {errors.email && (
                        <p className="text-danger">This field is required</p>
                      )}
                    </div>
                    <div className="mb-4 input_ps">
                      <div className="row g-3">
                        <div className="col-12 col-md-2 col-lg-3 input_ps">
                          <div className="mb-4 input_ps">
                            <input
                              type="text"
                              className="form-control"
                              name="mobileCountryCode"
                              disabled
                              {...register('mobileCountryCode', {
                                required: true,
                              })}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-10 col-lg-9 input_ps">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Phone Number"
                            name="mobile"
                            disabled
                            {...register('mobile', {required: true})}
                          />
                          <img src={PhoneIconSvg} />
                          {errors.mobile && (
                            <p className="text-danger">
                              This field is required
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="mb-4 input_ps">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="First Name"
                        name="firstName"
                        {...register('firstName', {required: true})}
                      />
                      <img src={UserIconSvg} />
                      {errors.firstName && (
                        <p className="text-danger">This field is required</p>
                      )}
                    </div>
                    <div className="mb-4 input_ps">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Last Name"
                        name="lastName"
                        {...register('lastName', {required: true})}
                      />
                      <img src={UserIconSvg} />
                      {errors.lastName && (
                        <p className="text-danger">This field is required</p>
                      )}
                    </div>
                    <div className="text-center">
                      <a
                        role={'button'}
                        className="main_btn mx-auto text-center mt-3"
                        onClick={handleSubmit(onSubmit)}>
                        Submit
                      </a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Wrapper>
  );
};

export default EditProfile;
