import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import HeroComponent from "../HeroComponent/HeroComponent";

const Header = () => {
  const { user } = useSelector((state) => state.authUser);
  // const navigate = useNavigate();

  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    const toggleButton = document.getElementById("navbarSupportedContent");
    toggle
      ? toggleButton.classList.toggle("show")
      : toggleButton.classList.replace("show", "hide");
  }, [toggle]);


  return (
    <>
      <section id="home" className="banner_backimage">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 main_menu">
              <nav
                id="navbar"
                className="navbar navbar-expand-lg navbar-light bg-none custom_navbar py-3"
              >
                <div className="container-fluid px-0">
                  <Link to={"/"} className="navbar-brand">
                    <img src={Logo} alt="Logo" />
                  </Link>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    onClick={() => setToggle(!toggle)}
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>

                  <div
                    className="collapse navbar-collapse"
                    id="navbarSupportedContent"
                  >
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0 navbar-nav ms-auto mb-0 mb-lg-0 d-flex align-items-center">
                      <li className="nav-item">
                        <a className="nav-link " href="#home">
                          Home
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#aboutus">
                          About us
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#subscribe">
                          Subscribe
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#download">
                          Download our App
                        </a>
                      </li>
                      <li className="nav-item">
                        <Link to={"/contact-us"} className="nav-link">
                          Contact Us
                        </Link>
                      </li>
                      <li className="nav-item">
                        {user === null && (
                          <Link
                            to={"/login"}
                            className="nav-link navLogin_btn mx-4"
                          >
                            Login
                          </Link>
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
          </div>
          <HeroComponent />
        </div>
      </section>
    </>
  );
};

export default Header;
