import { removeAllFromLocalStorage, setToLocalStorage } from "../helpers";
import { promoCode } from "../reducers/promoSlice";
import { aboutUs, termsPolicy } from "../reducers/staticDataSlice";
import { setUpdatedUserProfile } from "../reducers/updateUserProfileSlice";
import { eventList, loginSuccess, logout } from "../reducers/userSlice";
import api from "./index";

// User Login using Email
export const userLoginUsingEmail = (apiData) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post("/user/auth/login", apiData);
      dispatch(loginSuccess(res.data.data));
      setToLocalStorage("cfaderToken", res.data.data.userToken);
      setToLocalStorage("cfaderUserData", res.data.data.user);
      resolve(res.data);
    } catch (err) {
      reject(err.data);
    }
  });
};

// User login using Mobile with OTP
export const userLoginUsingMobile = (apiData) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
  
    try {
      const res = await api.post("/user/auth/login", apiData);
      resolve(res.data);
    } catch (err) {
      reject(err.data);
    }
  });
};

// User OTP verification
export const otpVerification = (apiData) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post("/user/auth/verify-login-otp", apiData);
      dispatch(loginSuccess(res.data.data));
      setToLocalStorage("cfaderToken", res.data.data.token);
      setToLocalStorage("cfaderUserData", res.data.data.user);
      resolve(res.data);
    } catch (err) {
      reject(err.data);
    }
  });
};

// OTP verification when user is trying to reset password
export const otpVerificationOnForgotPassword =
  (apiData) => async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.post(
          "/user/auth/forgot-password/verify-otp",
          apiData
        );
        resolve(res.data);
      } catch (err) {
        reject(err.data);
      }
    });
  };

// User Signup
export const userSignup = (apiData) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post("/user/auth/signup", apiData);
      dispatch(loginSuccess(res.data.data));
      resolve(res.data);
    } catch (err) {
      reject(err.data);
    }
  });
};

// User Logout
export const userLogout = () => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get("/user/auth/logout");
      removeAllFromLocalStorage();
      dispatch(logout());
      resolve(res.data);
    } catch (err) {
      reject(err.data);
    }
  });
};

// User Profile
export const getUserProfile = () => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get("/user/my-profile");
      dispatch(setUpdatedUserProfile(res.data.data));
      resolve(res.data);
    } catch (err) {
      reject(err.data);
    }
  });
};

// Edit User Profile
export const editUserProfile = (apiData) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post("/user/edit-profile", apiData);
      resolve(res.data);
    } catch (err) {
      reject(err.data);
    }
  });
};

// Forgot Password
export const forgotPassword = (apiData) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post("/user/auth/forgot-password", apiData);
      resolve(res.data);
    } catch (err) {
      reject(err.data);
    }
  });
};

// Forgot Email
export const forgotEmail = (apiData) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post("/user/auth/forget-email", apiData);
      resolve(res.data);
    } catch (err) {
      reject(err.data);
    }
  });
};

// OTP verification when user has forgot email
export const otpVerificationOnForgotEmail = (apiData) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post("/user/auth/forget-email/verify-otp", apiData);
      resolve(res.data);
    } catch (err) {
      reject(err.data);
    }
  });
};

// Change User Password
export const changeUserPassword = (apiData) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post("/user/change-password", apiData);
      resolve(res.data);
    } catch (err) {
      reject(err.data);
    }
  });
};

export const deleteAccount = (apiData) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get("/user/delete-account",);
      resolve(res.data);
    } catch (err) {
      reject(err.data);
    }
  });
};

// About Us Data
export const getAboutUs = () => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get("/staticdata/aboutus");
      dispatch(aboutUs(res.data.data));
      resolve(res.data);
    } catch (err) {
      reject(err.data);
    }
  });
};

// Terms & Policy Us Data
export const getTermsPolicy = (type) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(`/staticdata/${type}`);
      dispatch(termsPolicy(res.data.data));
      resolve(res.data);
    } catch (err) {
      reject(err.data);
    }
  });
};

// Terms & Policy Us Data
export const getEvents = () => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get("/event/list");
      dispatch(eventList(res.data.data));
      resolve(res.data);
    } catch (err) {
      reject(err.data);
    }
  });
};

// Contact to admin
export const contactToAdmin = (apiData) => async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post("/report/send-query", apiData);
      // dispatch(eventList(res.data.data))
      resolve(res.data);
    } catch (err) {
      reject(err.data);
    }
  });
};

// file upload
export const fileUpload = (data) => async () => {
  const apiData = {
    key: data.name,
    contentType: data.type,
  };
  return new Promise(async (resolve, reject) => {
    try {
      const response = await api.post("/file/generate-presigned-url", apiData);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const fileUploadAfterResponse =
  (presignedUrl, file, key) => async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(presignedUrl, {
          method: "PUT",
          body: file,
        });
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };

// Create free sibscription
export const createFreeSubscription = () => async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(
        "/subscription/free-subscription",
      );
      resolve(res.data);
    } catch (err) {
      reject(err.data);
    }
  });
};
// Create free sibscription
export const generateClientSecret = () => async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post(
        "/subscription/stripe-subscription",
      );
      resolve(res.data);
    } catch (err) {
      reject(err.data);
    }
  });
};


//cancel subscription
export const cancelPremiumSubscription = (subscriptionId) => async () => {

  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(`/subscription/cancel/${subscriptionId}`);
      // dispatch(eventList(res.data.data))
      resolve(res.data);
    } catch (err) {
      reject(err.data);
    }
  });
};

// verify promo code
export const verifyPromoCode = (promo) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(`/promocode/${promo}`);
      setToLocalStorage("promo", promo);
      dispatch(promoCode(res.data.data))
      resolve(res.data);
    } catch (err) {
      reject(err.data);
    }
  });
};
