import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { TopLogo } from "../../assets/images";
import FooterComp from "../Main/FooterComp";
import { useDispatch, useSelector } from "react-redux";

const Ecommerce = ({isPlaying,setIsPlaying,musicPlay,musicPause,timeoutId,innerVal}) => {
  const { events,isPlayingData } = useSelector((state) => state.authUser);
  const {check } = useSelector((state) => state.check);
    const dispatch = useDispatch()

  return (
    <div style={{ backgroundColor: "#111111" }}>
      <header id="home" className="mb-4">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 main_menu">
              <nav
                id="navbar"
                className="navbar navbar-expand-lg navbar-light bg-none custom_navbar py-3"
              >
                <div className="container-fluid px-0">
                  <Link to={"/"} className="navbar-brand">
                    <img src={TopLogo} alt="Logo" />
                  </Link>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>

      <section id="sign-up-new">
        <div className="container">
          <div className="row align-items-center justify-content-center mt-4">
            <div className="col-sm-12 col-md-7 col-lg-5">
              <div
                className="stream main image_event p-4 mt-5 mb-4 "
                style={{ backgroundColor: "#0F0F0F" }}
              >
                <h1>Cfader Swag</h1>
                <div>
                  <h5>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s with
                    the release of Letraset sheets containing Lorem Ipsum
                    passages, and more recently with desktop publishing software
                    

                  </h5>
                </div>
              </div>
            </div>
          </div>
          <FooterComp name={"ecommerce"} />
        </div>
      </section>
    </div>
  );
};

export default Ecommerce;
