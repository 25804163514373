import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  
  isplayingData:0,
  // isMusicPlay : false
};

const isPlayingSlice = createSlice({
  name: "isPlayingData",
  initialState,
  reducers: {
    
    getIsPlaying :(state,action) =>{
      state.isplayingData = action.payload
    },
    
    
  },
});

export const {
  getIsPlaying
} = isPlayingSlice.actions;

export default isPlayingSlice.reducer;
