import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  
  check:false,
  // isMusicPlay : false
};

const checkSlice = createSlice({
  name: "checkSlice",
  initialState,
  reducers: {
    
    checkMusicAnotherComponent :(state,action) =>{
      state.check = action.payload
    },
    
    
  },
});

export const {
    checkMusicAnotherComponent
  } = checkSlice.actions;
  
  export default checkSlice.reducer;