import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAboutUs } from "../../api/user";
import { ArrowLeft } from "../../assets/images";
import Wrapper from "../Main/Wrapper";

const AboutUs = ({globalRef,isPlaying,setIsPlaying,musicPlay,musicPause,timeoutId,innerVal}) => {
  const {check} = useSelector(state=>state.check)
  

  const {aboutus} = useSelector(state => state.staticData)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getAboutUs())
  },[])

  return (
    <Wrapper>
      <section id="sign-up-new">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-sm-12 col-md-7 col-lg-5">
              <div className="about">
                <div className="d-flex">
                 <Link to={'/settings'}>
                 <img src={ArrowLeft} />
                 </Link>
                  <h1 className="mx-3">About Us</h1>
                </div>
                <h6>About Cfader Radio</h6>
               <p dangerouslySetInnerHTML={{ __html: aboutus?.data?.text }}>
               </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Wrapper>
  );
};

export default AboutUs;
