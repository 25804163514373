import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./assets/css/aos.css";
import "./assets/css/admin-web.css";
import "react-toastify/dist/ReactToastify.css";
import "font-awesome/css/font-awesome.min.css";
import { Provider } from "react-redux";
// import store from "./store";
import { persistor, store } from "./store";
import { PersistGate } from "redux-persist/integration/react";



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>

      <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

