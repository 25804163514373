import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RadioLogo } from "../../assets/images";
import Wrapper from "../Main/Wrapper";
import OtpInput from "react-otp-input";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  otpVerificationOnForgotEmail,
  otpVerificationOnForgotPassword,
} from "../../api/user";

const VerifyForgotPasswordOtp = () => {
  const [otp, setOtp] = useState("");
  const handleChange = (otp) => setOtp(otp);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useLocation();

  const handleSubmit = () => {
    const apiData = {
      mobile: params?.state.mobile,
      mobileCountryCode: params?.state.countryCode,
      otp,
      email: params?.state.email || "",
      deviceId: "123564",
    };
    if (otp === "") {
      toast.error("Please enter OTP");
    } else {
      if (params?.state.email || params?.state.email === "") {
        dispatch(otpVerificationOnForgotPassword(apiData))
          .then((res) => {
            if (res.statusCode === 200) {
              toast.success(res.message);
              navigate("/");
            } else {
              toast.error(res.message);
            }
          })
          .catch((err) => {
            toast.error(err.message);
          });
      } else {
        dispatch(otpVerificationOnForgotEmail(apiData))
          .then((res) => {
            if (res.statusCode === 200) {
              toast.success(res.message);
              navigate("/");
            } else {
              toast.error("something went wrong");
            }
          })
          .catch((err) => {
            toast.error(err.message);
          });
      }
    }
  };
  return (
    <Wrapper>
      <section id="sign-up">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-sm-12 col-md-7 col-lg-5">
              <div className="login-mains">
                <div className="logo-main">
                  <img src={RadioLogo} />
                </div>
                <div className="card shadow py-4 px-4">
                  <div className="heading-logo pt-5">
                    <h4>User Verification</h4>
                    <p>
                      We sent you a verification code to &nbsp;
                      <span className="verify">
                        {params?.state.email
                          ? params?.state.email
                          : `${params?.state.countryCode}${params?.state.mobile}`}
                      </span>
                    </p>
                  </div>
                  <div className="sign-form user">
                    <form>
                      <div className="row g-3 justify-content-center">
                        <div className="col-10 input_ps">
                          <OtpInput
                            value={otp}
                            onChange={handleChange}
                            numInputs={4}
                            inputStyle={{
                              width: 60,
                              height: 50,
                              margin: "0 10px",
                            }}
                          />{" "}
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="content_sign text-center">
                    <a
                      role={"button"}
                      className="main_btn"
                      onClick={handleSubmit}
                    >
                      Submit
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Wrapper>
  );
};

export default VerifyForgotPasswordOtp;
