
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const jsmediatags = window.jsmediatags;


function useAudioPlayer() {
  const [duration, setDuration] = useState();
  const [curTime, setCurTime] = useState();
  const [playing, setPlaying] = useState(true);
  const [clickedTime, setClickedTime] = useState();
  const { mixList } = useSelector((state) => state.songs);
  const [songCover, setSongCover] = useState(null)

  

  useEffect(() => {
    const audio = document.getElementById("audio");
    // state setters wrappers
    const setAudioData = () => {
      setDuration(audio.duration);
      setCurTime(audio.currentTime);
    }

    const setAudioTime = () => setCurTime(audio.currentTime);

    // DOM listeners: update React state on DOM events
    audio.addEventListener("loadeddata", setAudioData);

    audio.addEventListener("timeupdate", setAudioTime);
    let i = 0;
    audio.addEventListener(
            "ended",
            function () {
              i = ++i < mixList.length ? i : 0;
              audio.src = mixList[i];
              jsmediatags.read(mixList[i], {
                onSuccess: function (tag) {
                  // setPlaylistDetails(tag.tags);
                  const { data, format } = tag.tags.picture;
                  let base64String = "";
                  for (var i = 0; i < data.length; i++) {
                    base64String += String.fromCharCode(data[i]);
                  }
                  const url = `data:${data.format};base64,${window.btoa(base64String)}`;
                  setSongCover(url);
                },
                onError: function (error) {
                  console.log(error);
                },
              });
              audio.play();
            },
            true
          );

    // React state listeners: update DOM on React state changes
    playing ? audio.play() : audio.pause();

    if (clickedTime && clickedTime !== curTime) {
      audio.currentTime = clickedTime;
      setClickedTime(null);
    } 

    // effect cleanup
    return () => {
      audio.removeEventListener("loadeddata", setAudioData);
      audio.removeEventListener("timeupdate", setAudioTime);
    }
  });

  return {
    curTime,
    duration,
    playing,
    setPlaying,
    setClickedTime,
    songCover
  }
}

export default useAudioPlayer;
