import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  aboutus: null,
  termspolicy: null,
};

const staticData = createSlice({
  name: 'staticData',
  initialState,
  reducers: {
    aboutUs: (state, action) => {
      state.aboutus = action.payload ;
    },
    termsPolicy: (state, action) => {
        state.termspolicy = action.payload ;
      },
  },
});

export const { aboutUs, termsPolicy  } = staticData.actions;

export default staticData.reducer;