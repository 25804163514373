import React from "react";

const CommonInput = ({ type, className, placeholder, name, register }) => {
  return (
    <input
      type={type || "text"}
      className={className}
      placeholder={placeholder}
      name={name}
      {...register(name, {required:true})}
    />
  );
};

export default CommonInput;
