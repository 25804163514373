import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import Player from "../components/Player";
import { getFromLocalStorage } from "../helpers";
import AboutUs from "../pages/AboutUs/AboutUs";
import CancelSubscription from "../pages/CancelSubscription/CancelSubscription";
import ChangePassword from "../pages/ChangePassword/ChangePassword";
import Contact from "../pages/Contact/Contact";
import ContactForm from "../pages/Contact/ContactForm";
import DemoPlay from "../pages/DemoPlay/DemoPlay";
import Ecommerce from "../pages/Ecommerce/Ecommerce";
import EditProfile from "../pages/EditProfile/EditProfile";
import Events from "../pages/Events/Events";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import VerifyForgotPasswordOtp from "../pages/ForgotPassword/VerifyForgotPasswordOtp";
import Home from "../pages/Home/Home";
import Login from "../pages/Login/Login";
import Main from "../pages/Main/Main";
import MyProfile from "../pages/MyProfile/MyProfile";
import NotFound404 from "../pages/NotFound404";
import Setting from "../pages/Setting/Setting";
import Signup from "../pages/Signup/Signup";
import SignupForPremium from "../pages/SignupForPremium/SignupForPremium";
import Stream from "../pages/Stream/Stream";
import Subscription from "../pages/Subscription/Subscription";
import TermsPolicy from "../pages/Terms&Policy/Terms&Policy";
import Privacy from "../pages/Privacy&Policy/Privacy&Policy";
import UpgradeSubscription from "../pages/UpgradeSubscription/UpgradeSubscription";
import UserSignupVerification from "../pages/UserVerification/UserSignupVerification";
import UserVerification from "../pages/UserVerification/UserVerification";
import { loginSuccess } from "../reducers/userSlice";
import PrivateRoutes from "./PrivateRoutes";

const Navigation = ({globalRef,isPlaying,setIsPlaying,musicPlay,musicPause,timeoutId,innerVal,setPathname}) => {
  const mainData = useRef()
  const token = getFromLocalStorage("cfaderToken");
  const user = getFromLocalStorage("cfaderUserData");
  const dispatch = useDispatch()
  const {pathname} = useLocation()

  useEffect(()=>{
    setPathname(pathname)
  },[pathname])

  useEffect(() => {
    if (Object.keys(getFromLocalStorage("cfaderUserData")).length) {
      dispatch(
        loginSuccess({
          token,
          user,
        })
      );
    }
    },[token])

    
  return (
    <Routes>
       <Route element={<PrivateRoutes />}>
      <Route path="/main" element={<Main ref={mainData} isPlaying={isPlaying} setIsPlaying={setIsPlaying} musicPause={musicPause} musicPlay ={musicPlay}  timeoutId={timeoutId } innerVal={innerVal} />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/contact-us" element={<ContactForm  isPlaying={isPlaying} setIsPlaying={setIsPlaying} musicPause={musicPause} musicPlay ={musicPlay} timeoutId={timeoutId } innerVal={innerVal} />}   />
      <Route path="/stream" element={<Stream  isPlaying={isPlaying} setIsPlaying={setIsPlaying} musicPause={musicPause} musicPlay ={musicPlay} innerVal={innerVal} timeoutId={timeoutId } />}   />
      <Route path="/events" element={<Events globalRef={globalRef} isPlaying={isPlaying} setIsPlaying={setIsPlaying} musicPause={musicPause} musicPlay ={musicPlay} timeoutId={timeoutId } innerVal={innerVal} />}   />
      <Route path="/settings" element={<Setting  isPlaying={isPlaying} setIsPlaying={setIsPlaying} musicPause={musicPause} musicPlay ={musicPlay}  timeoutId={timeoutId } innerVal={innerVal}  />} />
      <Route path="/my-profile" element={<MyProfile isPlaying={isPlaying} setIsPlaying={setIsPlaying} musicPause={musicPause} musicPlay ={musicPlay}  timeoutId={timeoutId } innerVal={innerVal}  />} />
      <Route path="/edit-profile" element={<EditProfile  isPlaying={isPlaying} setIsPlaying={setIsPlaying} musicPause={musicPause} musicPlay ={musicPlay} timeoutId={timeoutId } innerVal={innerVal}  />} />
      <Route path="/about-us" element={<AboutUs isPlaying={isPlaying} setIsPlaying={setIsPlaying} musicPause={musicPause} musicPlay ={musicPlay}  timeoutId={timeoutId } innerVal={innerVal}  />} />
      <Route path="/change-password" element={<ChangePassword isPlaying={isPlaying} setIsPlaying={setIsPlaying} musicPause={musicPause} musicPlay ={musicPlay} timeoutId={timeoutId } innerVal={innerVal}  />} />
      <Route path="/cancel-subscription" element={<CancelSubscription />} />
      <Route path="/upgrade-subscription" element={<UpgradeSubscription />} />
      <Route path="/subscription" element={<Subscription  isPlaying={isPlaying} setIsPlaying={setIsPlaying} musicPause={musicPause} musicPlay ={musicPlay} timeoutId={timeoutId } innerVal={innerVal}  />} />
      <Route path="/ecommerce" element={<Ecommerce  isPlaying={isPlaying} setIsPlaying={setIsPlaying} musicPause={musicPause} musicPlay ={musicPlay} timeoutId={timeoutId } innerVal={innerVal}  />}   />
       </Route>
      <Route path="/demo" element={<DemoPlay />} />
  
      <Route path="/login" element={<Login />} />
      <Route path="/user-verification" element={<UserVerification />} />
      <Route path="/user-verification-otp" element={<UserSignupVerification />} />
      <Route path="/" element={<Home />} />

      <Route
        path="/user-verification-on-forgot"
        element={<VerifyForgotPasswordOtp />}
      />
      <Route path="/terms-policy" element={<TermsPolicy />} />
      <Route path="/privacy-policy" element={<Privacy />} />
      <Route path="/forgot_password" element={<ForgotPassword />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/signup_premium" element={<SignupForPremium />} />
     
      <Route path="/*" element={<NotFound404 />} />
    </Routes>
  );
};

export default Navigation;
