import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { deleteAccount, getUserProfile, userLogout } from "../../api/user";
import {
  AboutIcon,
  ArrowRight,
  ClipboardIcon,
  DollarIcon,
  LogoutIcon,
  MyProfileImg,
  ProfileImg,
  ShareIcon,
  PrivacyPolicyIcon,
  ChangePasswordIcon,
  ContactUserIcon,
  UserProfile,
  deleteIcon,
} from "../../assets/images";
import FooterComp from "../Main/FooterComp";
import Wrapper from "../Main/Wrapper";
import "../../assets/css/admin-web.css";
import { loginSuccess, logout } from "../../reducers/userSlice";
import { getFromLocalStorage } from "../../helpers";
import InviteModal from "../../components/Modals/InviteModal";
import useAdsIntegration from "../../hooks/useAdsIntegration";
import { checkMusicAnotherComponent } from "../../reducers/checkSlice";
import DeleteConfirmation from "../../components/Modals/DeleteConfermationModal";
import { toast } from "react-toastify";

const Setting = ({isPlaying,setIsPlaying,musicPlay,musicPause,timeoutId,innerVal}) => {
  const { user } = useSelector((state) => state?.updatedUserProfile?.updatedUserProfile);
  const {check } = useSelector((state) => state.check);
  const [type, setType] = useState(null);
  const [id, setId] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();


  useEffect(() => {
    dispatch(getUserProfile());
  }, []);

  // handle logout
  const handleLogout = () => {
    musicPause()
    dispatch(checkMusicAnotherComponent(false))
    dispatch(userLogout())
      .then((res) => {
        dispatch(logout());
        navigate("/");
      })
      .catch((err) => console.log(err));
  };


  const showDeleteModal = (type, id) => {
    setDisplayConfirmationModal(true);
  };
 
  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const submitDelete = (type, id) => {
    dispatch(deleteAccount()).then((res)=>{
      toast.success(res.message);
      setDisplayConfirmationModal(false);
      navigate("/login")
    }).catch((err)=>{
      console.log(err.message)
      setDisplayConfirmationModal(false);
    })
    
  };

  return (
    <Wrapper>
      <section
        id="sign-up"
        style={{ marginTop: "12rem", backgroundColor: "#111111" }}
      >
        <div className="w-100" style={{ backgroundColor: "#111" }}>
          <div className="container mt-4 ">
            <div className="row align-items-center justify-content-center  mt-sm-0 mt-md-4 mt-lg-4">
              <div className="col-sm-12 col-md-8 col-lg-5 mt-sm-0 mt-md-4 mt-lg-4 mb-5">
                <div className="stream main mt-sm-0 mt-md-4 mt-lg-4">
                  <h1 className="text-center d-flex mb-4 justify-content-center">
                    Settings
                  </h1>
                  <div className="user_card d-flex align-items-center px-4">
                    <div className="user_img">
                      <img
                        src={user?.profileImage || MyProfileImg}
                        className="profile_img"
                      />
                    </div>
                    <div className="user_inform ms-4">
                      <span>{user?.firstName + " " + user?.lastName}</span>
                      <p>{user?.email}</p>
                    </div>
                  </div>
                  <nav className="navbar settings">
                    <div className="p-4">
                      <ul className="navbar-nav">
                        <li className="nav-item">
                          <Link
                            to={"/my-profile"}
                            className="nav-link nav-link-gray"
                          >
                            <img src={UserProfile} />
                            View Profile
                          </Link>
                          <a className="">
                            <img src={ArrowRight} />
                          </a>
                        </li>
                        <li className="nav-item">
                          <Link
                            to={"/about-us"}
                            className="nav-link nav-link-gray"
                          >
                            <img src={AboutIcon} />
                            About Us
                          </Link>
                          <a className="">
                            <img src={ArrowRight} />
                          </a>
                        </li>
                        <li className="nav-item">
                          <Link
                            to={"/change-password"}
                            className="nav-link nav-link-gray"
                          >
                            <img src={ChangePasswordIcon  } />
                            Change Password
                          </Link>
                          <a className="">
                            <img src={ArrowRight} />
                          </a>
                        </li>

                        <li className="nav-item">
                          <a className="nav-link nav-link-gray" href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#invite-popup">
                            <img src={ShareIcon} />
                            Invite Friend
                          </a>
                          <a className="">
                            <img src={ArrowRight} />
                          </a>
                        </li>
                        {/* {user?.subscription?.type === "premium" &&
                          user?.subscription?.type !== "free" && ( */}
                        <li
                          className="nav-item"
                          style={{
                            position: "relative",
                          }}
                        >
                          <Link
                            to={"/subscription"}
                            className="nav-link nav-link-gray"
                          >
                            <img src={DollarIcon} />
                            Subscription
                            <span
                              style={{
                                color: "#fff",
                                fontSize: "12px",
                                position: "absolute",
                                top: "10px",
                                backgroundColor: "#de1b22",
                                padding: "2px 5px",
                                borderRadius: "5px",
                                marginLeft: "5px",
                              }}
                            >
                              {user?.subscription?.type}
                            </span>
                          </Link>
                          <a className="">
                            <img src={ArrowRight} />
                          </a>
                        </li>
                        {/* )}

                        {user?.subscription?.type === "free" && ( */}
                        {/* <li className="nav-item">
                            <Link
                              to={"/upgrade-subscription"}
                              className="nav-link nav-link-gray"
                            >
                              <img src={DollarIcon} />
                              Upgrade Subscription
                            </Link>
                            <a className="">
                              <img src={ArrowRight} />
                            </a>
                          </li> */}
                        {/* )} */}

                        <li className="nav-item">
                          <Link
                            to={"/contact-us"}
                            className="nav-link nav-link-gray"
                          >
                            <img src={ContactUserIcon} />
                            Contact Us
                          </Link>
                          <a className="">
                            <img src={ArrowRight} />
                          </a>
                        </li>
                        <li className="nav-item">
                          <Link
                            to={"/terms-policy"}
                            className="nav-link nav-link-gray"
                          >
                            <img src={ClipboardIcon} />
                            Terms & Policy
                          </Link>
                          <a className="">
                            <img src={ArrowRight} />
                          </a>
                        </li>
                        <li className="nav-item">
                          <Link
                            to={"/privacy-policy"}
                            className="nav-link nav-link-gray"
                          >
                            <img src={PrivacyPolicyIcon} />
                            Privacy & Policy
                          </Link>
                          <a className="">
                            <img src={ArrowRight} />
                          </a>
                        </li>
                        <li className="nav-item">
                          <Link
                          to={""}
                            onClick={()=>showDeleteModal()}
                            className="nav-link nav-link-gray"
                          >
                            <img src={deleteIcon} />
                            Delete Account
                          </Link>
                          <a className="">
                            <img src={ArrowRight} />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </nav>
                  <div className="text-center">
                    <a
                      role={"button"}
                      onClick={() =>handleLogout()}
                      className="main_btn mx-auto text-center mt-3 setting"
                    >
                      <img src={LogoutIcon} className="me-3 " />
                      Logout
                    </a>
                  </div>
                </div>
              </div>
              <FooterComp name={"settings"} />
            </div>
          </div>
        </div>
      </section>
      <DeleteConfirmation showModal={displayConfirmationModal} confirmModal={submitDelete} hideModal={hideConfirmationModal} type={type} id={id} message={"Are you sure you want to delete your account?"}  />
      <InviteModal />
    </Wrapper>
  );
};

export default Setting;
