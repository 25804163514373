import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Subscribe from "../../components/Subscribe/Subscribe";
import SubscriptionBox from "../../components/Subscribe/SubscriptionBox";
import FooterComp from "../Main/FooterComp";
import Wrapper from "../Main/Wrapper";

const Subscription = () => {
  const { user } = useSelector((state) => state?.updatedUserProfile?.updatedUserProfile);
  return (
    <Wrapper>
      <section id="sign-up" style={{ backgroundColor: "#111111" }}>
        <div className="w-100" style={{ backgroundColor: "#111" }}>
          <div className="container mt-4 ">
            <h1 className="text-center text-white d-flex mb-4 justify-content-center">
              Subscription
            </h1>
            <div className="row align-items-center justify-content-center  mt-sm-0 mt-md-4 mt-lg-4">
              <div className="col-sm-12 col-md-12 col-lg-12 mt-sm-0 mt-md-4 mt-lg-4 mb-5">
                <div className="row d-flex justify-content-center ">
                  <div className="col-lg-4 col-md-12 mb-4">
                    <SubscriptionBox
                      planName="Free Plan"
                      cardRequired="No credit card required"
                      commercials="Commercials"
                      soundQuality="Lower Sound Quality"
                      mixesAccess="No Access to Exclusive Cfader Radio Mixes"
                      deviceAtATime="Accessible by one (1) Device at a time"
                      subscription="Free 14-Day Trial of Premium Plan described below"
                      selected={
                        user?.subscription?.type === "free" 
                          ? "2px solid #de1b22"
                          : "none"
                      }
                    />
                  </div>
                  <div className="col-lg-4 col-md-12 mb-4">
                    <SubscriptionBox
                      planName="Premium Plan"
                      price="$6.99/month"
                      cardRequired="No Commercials"
                      commercials="Premium Sound Quality"
                      soundQuality="Access to All Cfader Mixes On-Demand"
                      deviceAtATime="Accessible by three (3) Device at a time"
                      subscription="Create and Customize Your Own Play Lists"
                      selected={
                        user?.subscription?.type === "premium" || user?.subscription?.type === "freeTrial"
                          ? "2px solid #de1b22"
                          : "none"
                      }
                    />
                    <div className="text-center mt-4">
                      {user?.subscription?.type === "free" && (
                        <Link
                          className="sign_up_plan"
                          to={"/upgrade-subscription"}
                        >
                          UPGRADE PLAN
                        </Link>
                      ) }
                      {user?.subscription?.type === "premium" && !user?.subscription?.isCancelAtPeriodEnd &&  (
                        <Link
                          className="sign_up_plan"
                          to={"/cancel-subscription"}
                        >
                          CANCEL SUBSCRIPTION
                        </Link>
                      )}
                    </div>
                   
                  </div>
                  <div className="col-md-12 text-center">
                  {user?.subscription?.isCancelAtPeriodEnd &&  <div className="text-white text-white">Your subscription is valid till {moment(user?.subscription?.endDate).format('ll')}</div>}
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Wrapper>
  );
};

export default Subscription;
