import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { forgotEmail, forgotPassword } from "../../api/user";
import {
  EnvelopeSvg,
  PhoneIconSvg,
  RadioLogo,
  TopLogo,
} from "../../assets/images";
import CommonInput from "../../components/common/CommonInput";
import country from "../../utils/country.json";

const ForgotPassword = () => {
  const [resetPasswordUsing, setResetPasswordUsing] = useState("email");
  const [selectedOption, setSelectedOption] = useState(country[0].value);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const apiData = {
      email: data.email || "",
      mobile: data.phone || "",
      mobileCountryCode: "+1" || "",
    };
    const forgotEmailApiData = {
      mobile: data.phone ,
      mobileCountryCode: "+1" ,
    };
    if(resetPasswordUsing === "email"){
      dispatch(forgotEmail(forgotEmailApiData))
      .then((res) => {
        if (res.statusCode === 200) {
          toast.success(res.message);
          navigate("/user-verification-on-forgot", {
            state: {
              mobile: data.phone,
              countryCode: selectedOption || "+1",
            },
          });
        } else {
          toast.error("something went wrong");
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
    }else{
    dispatch(forgotPassword(apiData))
      .then((res) => {
        if (res.statusCode === 200) {
          toast.success(res.message);
          navigate("/user-verification-on-forgot", {
            state: {
              email: data.email,
              mobile: data.phone,
              countryCode: selectedOption || "+1",
            },
          });
        } else {
          toast.error("something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something went wrong");
      });
    }
  };

  const handlePasswordResetUsing = (e) => {
    if (resetPasswordUsing === "email") {
      return (
        <div className="row g-3">
          <div className="col-2 input_ps">
            <select
              className="form-select"
              onChange={(e) => setSelectedOption(e.target.value)}
            >
              {country.map((v, i) => (
                <option
                  key={i}
                  value={v.dial_code}
                >
                  {v.dial_code}
                </option>
              ))}
            </select>
          </div>
          <div className="col-10 input_ps">
            <CommonInput
              type={"text"}
              className={"form-control"}
              placeholder={"Phone Number"}
              name={"phone"}
              register={register}
            />
            <img src={PhoneIconSvg} />
            {errors.phone && (
              <p className="text-danger">Phone is required</p>
            )}
          </div>
        </div>
      
      );
    } else {
      return (
        <div className="row g-3">
          <div className="col-2 input_ps">
            <select
              className="form-select"
              onChange={(e) => setSelectedOption(e.target.value)}
            >
              {country.map((v, i) => (
                <option
                  key={i}
                  value={v.dial_code}
                  // {...register("mobileCountryCode")}
                >
                  {v.dial_code}
                </option>
              ))}
            </select>
          </div>
          <div className="col-10 input_ps">
            {/* <CommonInput
              type={"text"}
              className={"form-control"}
              placeholder={"Phone Number"}
              name={"phone"}
              register={register}
            /> */}
              <input
              type="phone"
              name="phone"
              className="form-control"
              placeholder="Phone Number"
              {...register("phone")} />
            <img src={PhoneIconSvg} />
            {errors.phone && (
              <p className="text-danger">Phone is required</p>
            )}
          </div>
          <h6 className="text-center">OR</h6>
          <div className="mb-4 input_ps">
            {/* <CommonInput
              type={"text"}
              className={"form-control"}
              placeholder={"Email Address"}
              name={"email"}
              register={register}
            /> */}
            <input
              type="email"
              name="email"
              className="form-control"
              placeholder="Email Address"
              {...register("email")} />
            <img src={EnvelopeSvg} />
            {errors.email && <p className="text-danger">Email is required</p>}
          </div>
        </div>
      );
    }
  };
  return (
    <div className="bg-black">
      <header id="home">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 main_menu">
              <nav
                id="navbar"
                className="navbar navbar-expand-lg navbar-light bg-none custom_navbar py-3"
              >
                <div className="container-fluid px-0">
                  <a className="navbar-brand" href="index.html">
                    <img src={TopLogo} alt="Logo" />
                  </a>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>

      <section id="sign-up">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-sm-12 col-md-7 col-lg-5">
              <div className="login-mains">
                <div className="logo-main">
                  <img src={RadioLogo} />
                </div>

                <div className="card shadow py-4 px-4">
                  <div className="heading-logo pt-5">
                    <h4>Forgot Login Credentials</h4>
                  </div>
                  <div className="sign-form">
                    <form>
                      <div className="mb-4">
                        <div className="form-check  form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="email"
                            id="email"
                            checked={
                              resetPasswordUsing === "email" ? true : false
                            }
                            onClick={() => {
                              setResetPasswordUsing("email");
                              reset({
                                email: "",
                              });
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault2"
                          >
                            Forgot Email Address
                          </label>
                        </div>
                        <div className="form-check  form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="phone"
                            id="phone"
                            checked={
                              resetPasswordUsing !== "email" ? true : false
                            }
                            onClick={() => {
                              setResetPasswordUsing("phone");
                              reset({
                                phone: "",
                              });
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault1"
                          >
                            Forgot Password
                          </label>
                        </div>
                      </div>
                      {handlePasswordResetUsing()}
                     
                    </form>
                  </div>
                  <div className="content_sign text-center">
                    <a
                      role={"button"}
                      onClick={handleSubmit(onSubmit)}
                      className="main_btn"
                      href="login.html"
                    >
                      Submit
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ForgotPassword;
