import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { verifyPromoCode } from "../../api/user";
import CheckoutForm from "../../pages/SignupForPremium/CheckoutForm";
import PaymentModal from "./PaymentModal";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const UpgradeToPremium = (props) => {
  const [modalToShow, setModalToShow] = useState("");
  const [modalShow, setModalShow] = useState(true);
  const [show, setShow] = useState(false);
  const { token } = useSelector((state) => state.authUser);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [clientSecret, setClientSecret] = useState("");
  const dispatch = useDispatch();

  const [promo_code, setPromoCode] = useState("");
  const [promoApplied, setPromoApplied] = useState(false);

  const verifyThePromo = (e) => {
    e.preventDefault();
    dispatch(verifyPromoCode(promo_code))
      .then((res) => {
        if (res?.data?.isExist === true) {
          toast.success("Promo code applied successfully");
          setPromoApplied(true);
          generateClientSecret();
        } else {
          toast.error("Promo code is not valid");
          setPromoApplied(false);
        }
      })
      .catch((err) => toast.error(err.message));
  };



  const generateClientSecret = async () => {
    try {
      await fetch(
        "https://api.cfader.com/v1/subscription/stripe-subscription",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ promo_code }),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setClientSecret(data.data.clientSecret);
        });
    } catch (error) {
      toast.error(error.message);
    }
  };

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div
      className="modal fade"
      id="premium-popup"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered rate-popup">
        <div className="modal-content">
          <div class="modal-header border-bottom-0 pb-0 pt-0">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
          <div className="heading-logo pt-5 text-center">
          <h4>Payment ($6.99/month)</h4>
        </div>
        <div className="modal-body">
          <div className="sign-form">
            <div className="mb-2 input_ps">
              <input
                type={"text"}
                className="form-control"
                placeholder="Promo Code"
                name="promo_code"
                disabled={promoApplied}
                // {...register("promo_code")}
                onChange={(e) => setPromoCode(e.target.value)}
              />
              <span className="text-success"></span>
              <div className="promo">
                <button
                  className="btn text-white"
                  disabled={promoApplied || promo_code === "" ? true : false}
                  onClick={verifyThePromo}
                >
                  Apply
                </button>
              </div>
            </div>

            {promoApplied && (
              <p className="text-success">
                <span className="text-bold h6">{promo_code}</span> applied
                successfully.
              </p>
            )}
            {clientSecret ? (
              <Elements options={options} stripe={stripePromise}>
                <CheckoutForm />
              </Elements>
            ) : (
              <div className="d-flex align-items-center justify-content-between mt-3">
                <div>Don't have a Promo? </div>
                <button
                  className="btn btn-danger w-12"
                  onClick={() => generateClientSecret()}
                >
                  Make payment
                </button>
              </div>
            )}
          </div>
        </div>
           
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpgradeToPremium;
