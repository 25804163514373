import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { cancelPremiumSubscription } from "../../api/user";
import { ArrowLeft } from "../../assets/images";
import Wrapper from "../Main/Wrapper";
import { toast } from "react-toastify";

const CancelSubscription = () => {

  const { user } = useSelector((state) => state.updatedUserProfile.updatedUserProfile);
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const handleCancelSubscription = () => {
    dispatch(
      cancelPremiumSubscription(user?.subscription?.stripeSubscriptionId)
    )
      .then((res) =>{toast.success("subscription cancelled");navigate("/settings") })
      .catch((err) => console.log(err));
  };

  return (
    <Wrapper>
      <section
        id="sign-up-new"
        style={{
          height: "calc(100vh - 50px)",
        }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-sm-12 col-md-7 col-lg-5">
              <div className="abouts">
                <div className="d-flex">
                  <Link to={"/settings"}>
                    <img src={ArrowLeft} />
                  </Link>
                  <h1 className="mx-3 mb-3">Cancel Subscription</h1>
                </div>
                <div className="info-p">
                  <p>Are you sure want to Cancel Subscription</p>
                </div>
                <form className="conatct_fm">
                  <div className="mb-4 input_ps">
                    <select className="form-select form-control">
                      <label>Reasons</label>
                      <option value="1">Music is too repetitive</option>
                      <option value="2">Service is too expensive</option>
                      <option value="3">Not enough mixes</option>
                      <option value="4"> I don’t listen to Cfader</option>
                      <option value="5"> Other</option>
                    </select>
                  </div>

                  <div className="text-center">
                    <a role={'button'} onClick={handleCancelSubscription} className="main_btns mx-auto text-center mt-3 me-4">
                      Yes
                    </a>
                    <a className="main_btns mx-auto text-center mt-3">No</a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Wrapper>
  );
};

export default CancelSubscription;
