import React, { useEffect } from "react";
import SendSvg from "../../assets/images/send.svg"
import { useSelector } from "react-redux";

const Contact = ({globalRef,isPlaying,setIsPlaying,musicPlay,musicPause,timeoutId,innerVal}) => {
  const {check} = useSelector(state=>state.check)
  return (
    <section className="section_padding_edits" id="contact">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <div className="contact_us_info mt-4">
              <p className="d-flex align-items-center text_black text-start mt-4 px-0">
                <a  className="me-3">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14.454"
                      height="26.987"
                      viewBox="0 0 14.454 26.987"
                    >
                      <path
                        id="fb"
                        d="M15.116,15.18l.75-4.884H11.179V7.127a2.442,2.442,0,0,1,2.754-2.639h2.131V.33A25.981,25.981,0,0,0,12.282,0C8.422,0,5.9,2.339,5.9,6.574V10.3H1.609V15.18H5.9V26.987h5.28V15.18Z"
                        transform="translate(-1.609)"
                        fill="#3c65f5"
                      ></path>
                    </svg>
                  </span>
                </a>
                <a  className="me-3">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20.946"
                      height="20.946"
                      viewBox="0 0 20.946 20.946"
                    >
                      <path
                        id="linkedin"
                        d="M4.689,20.947H.346V6.962H4.689ZM2.515,5.054A2.527,2.527,0,1,1,5.03,2.516,2.536,2.536,0,0,1,2.515,5.054ZM20.942,20.947H16.608V14.139c0-1.622-.033-3.7-2.258-3.7-2.258,0-2.6,1.763-2.6,3.586v6.924H7.409V6.962h4.165V8.87h.061a4.563,4.563,0,0,1,4.109-2.258c4.395,0,5.2,2.894,5.2,6.653v7.682Z"
                        transform="translate(0 0)"
                        fill="#00a1d3"
                      ></path>
                    </svg>
                  </span>
                </a>
                <a  className="me-3">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25.771"
                      height="20.931"
                      viewBox="0 0 25.771 20.931"
                    >
                      <path
                        id="twitter"
                        d="M23.122,8.6c.016.229.016.458.016.687A14.925,14.925,0,0,1,8.111,24.312,14.926,14.926,0,0,1,0,21.941a10.926,10.926,0,0,0,1.275.065A10.578,10.578,0,0,0,7.833,19.75a5.291,5.291,0,0,1-4.938-3.663,6.661,6.661,0,0,0,1,.082,5.586,5.586,0,0,0,1.39-.18A5.283,5.283,0,0,1,1.047,10.8v-.065a5.319,5.319,0,0,0,2.387.67A5.29,5.29,0,0,1,1.8,4.346,15.013,15.013,0,0,0,12.689,9.873a5.963,5.963,0,0,1-.131-1.21A5.287,5.287,0,0,1,21.7,5.049a10.4,10.4,0,0,0,3.352-1.275A5.268,5.268,0,0,1,22.73,6.684a10.589,10.589,0,0,0,3.042-.818A11.354,11.354,0,0,1,23.122,8.6Z"
                        transform="translate(0 -3.381)"
                        fill="#40caf5"
                      ></path>
                    </svg>
                  </span>
                </a>
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="custom-block mb-4 w-100">
              <h6 className="contact_texts"> Contact Us</h6>
            </div>
            <form className="row g-3 custom_form">
              <div className="col-sm-12 mb-2">
                <label className="f_18 text_gry">Your Name</label>
                <input
                  type="email"
                  className="form-control"
                  id="inputEmail4"
                  placeholder="Name"
                />
              </div>
              <div className="col-sm-12 mb-2">
                <label className="f_18  text_gry">Your Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="inputEmail4"
                  placeholder="Email"
                />
              </div>
              <div className="col-sm-12">
                <label className="f_18  text_gry">Subject</label>
                <select className="form-select">
                  <option selected>General Message</option>
                  <option value="1">Radio Support</option>
                  <option value="2">App Support</option>
                  <option value="3">Subscription Support</option>
                  <option value="4">Advertising Inquiry</option>
                  <option value="5">Other</option>
                </select>
              </div>
              <div className="col-sm-12 mb-2">
                <label className="f_18 text_gry">Your Message</label>
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="5"
                  placeholder="Message"
                ></textarea>
              </div>
              <div className="col-sm-5">
                <a role={'button'} className="sign_up_plan sub" href="">
                  Send <img src={SendSvg} />
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
