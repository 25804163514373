import React from "react";

import Song from "./Song";
import Play from "./Play";
import Pause from "./Pause";
import Bar from "./Bar";
import useAudioPlayer from "../../hooks/useAudioPlayer";
import { useSelector } from "react-redux";

function Audio() {
  const { curTime, duration, playing, setPlaying, setClickedTime } =
    useAudioPlayer();
  const { mixList, playlist } = useSelector((state) => state.songs);

  var jsmediatags = window.jsmediatags;


  return (
    <div className="player">
      <audio id="audio">
        <source src={mixList[0]} />
        Your browser does not support the <code>audio</code> element.
      </audio>
      <div className="controls">
        {playing ? (

          <div className="play-btn" onClick={() => setPlaying(false)}>
            <i
              className="fa fa-pause"
              style={{
                color: "#5c5a5a",
                fontSize: "2rem",
              }}
            ></i>
          </div>
        ) : (
          <div className="play-btn" onClick={() => setPlaying(true)}>
            <i
              className="fa fa-play"
              style={{
                color: "#fff",
                fontSize: "2rem",
              }}
            ></i>
          </div>
        )}
        <Bar
          curTime={curTime}
          duration={duration}
          onTimeUpdate={(time) => setClickedTime(time)}
        />
      </div>
    </div>
  );
}

export default Audio;
