import React, { useState } from "react";
import { useForm } from "react-hook-form";
import ReactStars from "react-rating-stars-component";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getAllMixes, submitReview } from "../../api/song";
import $ from "jquery";

const RatingModal = ({ data }) => {
  const { name, _id } = data;
  const [modalToShow, setModalToShow] = useState("");

  // useForm hook
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [rating, setRating] = React.useState(0);
  const dispatch = useDispatch();

  const ratingChanged = (newRating) => {
    setRating(newRating);
  };

  // submit rating
  const submitRating = () => {
    const apiData = {
      starCount: rating,
      mix: _id,
    };

    dispatch(submitReview(apiData))
      .then((res) => {
        toast.success("Review submitted successfully");
        setModalToShow("modal");
        $("#rate-popup").hide();
        $(".modal-backdrop").hide();

        dispatch(getAllMixes());
      })
      .catch((err) => {
        setModalToShow("modal");
        toast.error("Error submitting review");
        console.log(err);
      });
  };
  return (
    <div
      className="modal fade"
      id="rate-popup"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered rate-popup">
        <div className="modal-content">
          <div class="modal-header border-bottom-0 pb-0 pt-0">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="input_ps"></div>
            <div className="rate-info mt-0">
              <h3 className="text-center">Rate Now</h3>
              <h4 className="mb-4">
                Rate your experience with
                <span>{name}</span>
              </h4>
              <div className="star-rating d-flex justify-content-center">
                <ReactStars
                  count={5}
                  onChange={ratingChanged}
                  size={40}
                  activeColor="#ffd700"
                  isHalf={true}
                  value={rating}
                />
              </div>
              <div className="d-flex justify-content-center mt-2">
                <a
                  role={"button"}
                  onClick={handleSubmit(submitRating)}
                  href=""
                  className="btn-rate"
                  data-bs-dismiss={modalToShow}
                >
                  Submit
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RatingModal;
