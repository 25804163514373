import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getFromLocalStorage } from "../helpers";


const PrivateRoutes = () => {
    const token = getFromLocalStorage("cfaderToken");
  const user = getFromLocalStorage("cfaderUserData");
    return user && token ? <Outlet /> : <Navigate to="/login" />
}

export default PrivateRoutes