import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    promo: null,
};

const promoSlice = createSlice({
    name: "promoSlice",
    initialState,
    reducers: {
        promoCode: (state, action) => {
            state.promo = action.payload;
        },
    },
});

export const { promoCode } = promoSlice.actions;

export default promoSlice.reducer;