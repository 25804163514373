import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  EnvelopeSvg,
  PhoneIconSvg,
  RadioLogo,
  TopLogo,
  UserIconSvg,
} from "../../assets/images";
import PaymentSuccessModal from "../../components/Modals/PaymentSuccessModal";
import PaymentFailedModal from "../../components/Modals/PaymentFailedModal";
import { useDispatch, useSelector } from "react-redux";
import {
  fileUpload,
  fileUploadAfterResponse,
  userSignup,
  verifyPromoCode,
} from "../../api/user";
import { convertFileIntoUrl } from "../../helpers";
import { useForm } from "react-hook-form";
import useShowPassword from "../../hooks/useShowPassword";
import countries from "../../utils/country.json";
import { toast } from "react-toastify";

const SignupForPremium = () => {
  const { user } = useSelector((state) => state.authUser);
  const navigate = useNavigate();
  const inputFile = useRef(null);
  const dispatch = useDispatch();
  const [src, setSrc] = useState("");
  const [srcUrl, setSrcUrl] = useState("");
  const [isAgreeWithTNC, setIsAgreeWithTNC] = useState(false);
  const { showPassword, renderIcon } = useShowPassword();
  const [promo_code, setPromoCode] = useState("");
  const [promoApplied, setPromoApplied] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [promo_code_duration, setPromoCodeDuration] = useState("");

  const uploadToAWs = (file, setState, key, state) => {
    dispatch(fileUpload(file))
      .then((res) => {
        if (res.statusCode === 200) {
          if (key === "video") {
            let urlArr = [...state];
            urlArr.push({ file: res.data.fileName, title: "video" });
            setState(urlArr);
          } else {
            setState(res.data.fileName);
          }
          dispatch(fileUploadAfterResponse(res.data.preSignedUrl, file));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  const onFileChange = (e) => {
    const fileObj = e.target.files[0];
    if (fileObj) {
      if (fileObj.type === "image/png" || fileObj.type === "image/jpeg") {
        convertFileIntoUrl(fileObj, setSrc);
        uploadToAWs(fileObj, setSrcUrl, "image");
      }
    }
  };

  const onButtonClick = () => {
    inputFile.current.click();
  };

  const handleSignup = (data) => {
    Object.assign(
      data,
      { profileImage: srcUrl },
      { subscriptionTypeSelection: "premium" }
    );

    dispatch(userSignup(data))
      .then((res) => {
        if (res) {
          toast.success(res.data.otp);
          setModalShow(true);
          navigate("/user-verification-otp", {
            state: {
              token: res.data.userToken,
              mobile: data.mobile,
              countryCode: data.mobileCountryCode || "+91",
            },
          });
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  return (
    <div
      style={{
        backgroundColor: "#000",
      }}
    >
      <section id="home" className="mb-0">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 main_menu">
              <nav
                id="navbar"
                className="navbar navbar-expand-lg navbar-light bg-none custom_navbar py-3"
              >
                <div className="container-fluid px-0">
                  <a
                    className="navbar-brand"
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/");
                    }}
                  >
                    <img src={TopLogo} alt="Logo" />
                  </a>
                  <button
                    className="navbar-toggler "
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section id="sign-up-new">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-sm-12 col-md-7 col-lg-5">
              <div className="login-mains">
                <div className="logo-main">
                  <img
                    src={user?.profileImage || src || RadioLogo}
                    className="signup-user-profile"
                    role="button"
                    onClick={onButtonClick}
                  />
                  <input
                    type="file"
                    id="file"
                    ref={inputFile}
                    style={{ display: "none" }}
                    onChange={onFileChange}
                  />
                </div>
                <div className="card shadow py-4 px-4">
                  <div className="heading-logo pt-5 mb-4">
                    <h4>Sign up</h4>
                  </div>
                  <div className="sign-form">
                    <form>
                      <div className="mb-4 input_ps">
                        <input
                          type="text"
                          className="form-control"
                          name="email"
                          disabled={user !== null ? true : false}
                          placeholder="Email Address"
                          {...register("email", { required: true })}
                        />
                        <img src={EnvelopeSvg} />
                        {errors?.email && (
                          <span className="text-danger">
                            This field is required
                          </span>
                        )}
                      </div>
                      <div className="row g-3">
                        <div className="col-4 input_ps">
                          {user === null ? (
                            <select
                              className="form-select mb-3 form-control"
                              style={{
                                backgroundColor: "#EFEFEF",
                              }}
                              name="mobileCountryCode"
                              {...register("mobileCountryCode", {
                                required: true,
                              })}
                            >
                              {countries &&
                                countries.map((country) => (
                                  <option
                                    key={country.name}
                                    value={country.dial_code}
                                  >
                                    {country.code} {country.dial_code}
                                  </option>
                                ))}
                            </select>
                          ) : (
                            <input
                              type="text"
                              className="form-control mb-3"
                              name="mobileCountryCode"
                              disabled={user !== null ? true : false}
                              {...register("mobileCountryCode", {
                                required: true,
                              })}
                            />
                          )}
                        </div>
                        <div className="col-8 input_ps">
                          <input
                            type="text"
                            className="form-control"
                            name="mobile"
                            placeholder="Mobile Number"
                            disabled={user !== null ? true : false}
                            {...register("mobile", { required: true })}
                          />
                          <img src={PhoneIconSvg} />
                          {errors?.mobile && (
                            <span className="text-danger">
                              This field is required
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="mb-4 input_ps">
                        <input
                          type="text"
                          className="form-control"
                          name="firstName"
                          disabled={user !== null ? true : false}
                          placeholder="First Name"
                          {...register("firstName", { required: true })}
                        />
                        <img src={UserIconSvg} />
                        {errors?.firstName && (
                          <span className="text-danger">
                            This field is required
                          </span>
                        )}
                      </div>
                      <div className="mb-4 input_ps">
                        <input
                          type="text"
                          className="form-control"
                          disabled={user !== null ? true : false}
                          placeholder="Last Name"
                          {...register("lastName", { required: true })}
                        />
                        <img src={UserIconSvg} />
                        {errors?.lastName && (
                          <span className="text-danger">
                            This field is required
                          </span>
                        )}
                      </div>

                      {user === null && (
                        <>
                          <div className="mb-4 input_ps">
                            <input
                              type={showPassword ? "text" : "password"}
                              className="form-control"
                              placeholder="Password"
                              name="password"
                              {...register("password", { required: true })}
                            />
                            {/* <img src={LockSvg} /> */}
                            {renderIcon()}
                            {errors?.password && (
                              <span className="text-danger">
                                This field is required
                              </span>
                            )}
                          </div>
                          <div className="mb-4 input_ps">
                            <input
                              type={showPassword ? "text" : "password"}
                              className="form-control"
                              placeholder="Confirm Password"
                              name="confirmPassword"
                              {...register("confirmPassword", {
                                required: true,
                              })}
                            />

                            {/* <img src={LockSvg} /> */}
                            {renderIcon()}
                            {errors?.confirmPassword && (
                              <span className="text-danger">
                                This field is required
                              </span>
                            )}
                          </div>
                          {/* <div className="mb-4 input_ps">
                            <input
                              type={'text'}
                              className="form-control"
                              placeholder="Promo Code"
                              name="promo_code"
                              {...register('promo_code')}
                              onChange={e => setPromoCode(e.target.value)}
                            />
                            <span className="text-success"></span>
                            <div className="promo">
                              <button
                                className="btn text-white"
                                disabled={
                                  promoApplied && promo_code === ''
                                    ? true
                                    : false
                                }
                                onClick={verifyThePromo}>
                                Apply
                              </button>
                            </div>
                            {promoApplied && promo_code && (
                              <p className="text-success mt-2">
                                <span className="text-bold h6">
                                  {promo_code_duration} successfully.
                                </span>
                              </p>
                            )}
                          </div> */}
                        </>
                      )}
                    </form>
                  </div>
                  <div className="content_sign text-center">
                    <a href="#" className="text-sign-mains">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          onChange={(e) => setIsAgreeWithTNC(e.target.checked)}
                          // value={isAgreeWithTNC}
                          id="isAgreeWithTNC"
                          name="isAgreeWithTNC"
                          {...register("isAgreeWithTNC")}
                        />
                        Accept all the <span>Terms of Services </span> and
                        <span> Privacy Policy </span>
                      </div>
                    </a>
                    <button
                      type="submit"
                      onClick={handleSubmit(handleSignup)}
                      // onClick={() => setModalShow(true)}
                      role={"button"}
                      className="sign_up_plan"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <PaymentSuccessModal />
        <PaymentFailedModal />
      </section>
    </div>
  );
};

export default SignupForPremium;
