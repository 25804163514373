import React from "react";
import { Link, NavLink} from "react-router-dom";

const FooterComp = ({ name }) => {
  const activeClass = name === "main" ? "active" : "";
 

  const openLink = () => {
    const externalLink = 'https://cfadermarketplace.creator-spring.com/apparel';
    window.location.href = externalLink;
  };
  return (
    <div className="row mb-4">
      <div className="col-md-12">
        <div className="bootom_navbar d-flex justify-content-center aligns-item-center">
          <nav className="navbar navbar-expand-sm">
            <div className="container-fluid justify-content-center">
              <ul className="navbar-nav">
                <li className={`nav-item activeClass`}>
                  <Link
                    to={"/main"}
                    className="nav-link"
                    style={{
                      color: name == "main" ? "#de1b22" : "gray",
                    }}
                  >
                    <svg
                      id="music-notess"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      // color
                      fill="none"
                      viewBox="0 0 20 20"
                    >
                      <g
                        id="Group_12602"
                        data-name="Group 12602"
                        transform="translate(7.263 4.954)"
                      >
                        <g id="Group_12601" data-name="Group 12601">
                          <path
                            id="Path_33845"
                            data-name="Path 33845"
                            d="M193,121.016l-8.119,2.309a.578.578,0,0,0-.419.555v7.863a2.282,2.282,0,0,0-1.154-.321,2.309,2.309,0,1,0,2.309,2.309v-5.952l6.965-1.979v3.635a2.282,2.282,0,0,0-1.154-.321,2.309,2.309,0,1,0,2.309,2.309v-9.851A.578.578,0,0,0,193,121.016Z"
                            transform="translate(-181 -120.993)"
                            fill={name == "main" ? "#de1b22" : "#828282"}
                          />
                        </g>
                      </g>
                      <g id="Group_12604" data-name="Group 12604">
                        <g id="Group_12603" data-name="Group 12603">
                          <path
                            id="Path_33846"
                            data-name="Path 33846"
                            d="M6.68,3.565C5.55,2.871,4.618,2.285,4.618.577a.577.577,0,1,0-1.154,0V10.749a2.282,2.282,0,0,0-1.154-.321,2.309,2.309,0,1,0,2.309,2.309V7.229a10.144,10.144,0,0,0,1.25.788c1.209.7,2.252,1.306,2.252,2.989a.577.577,0,1,0,1.154,0V7.542C9.274,5.156,7.9,4.311,6.68,3.565Z"
                            fill={name == "main" ? "#de1b22" : "#828282"}
                          />
                        </g>
                      </g>
                    </svg>
                    Player
                  </Link>
                </li>
                <li className={`nav-item `}>
                  <Link
                    to={"/stream"}
                    className="nav-link "
                    style={{
                      color: name == "stream" ? "#de1b22" : "gray",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20.36"
                      viewBox="0 0 20 20.36"
                    >
                      <g id="music" transform="translate(-10.305 0)">
                        <path
                          id="Path_33847"
                          data-name="Path 33847"
                          d="M19.59,0a9.283,9.283,0,1,0,9.285,9.283A9.284,9.284,0,0,0,19.59,0Zm0,11.447a2.164,2.164,0,1,1,2.165-2.164A2.167,2.167,0,0,1,19.59,11.447Z"
                          transform="translate(0 0)"
                        />
                        <path
                          id="Path_33848"
                          data-name="Path 33848"
                          d="M132.531,117.551a4.675,4.675,0,1,0,3.211,8.071v-1.738a.277.277,0,0,1,.211-.269l1.12-.277a4.676,4.676,0,0,0-4.542-5.787Zm0,6.869a2.193,2.193,0,1,1,2.193-2.193A2.2,2.2,0,0,1,132.531,124.42Z"
                          transform="translate(-112.926 -112.924)"
                          fill={name == "stream" ? "#de1b22" : "#5a5a5a"}
                        />
                        <g
                          id="Group_12605"
                          data-name="Group 12605"
                          transform="translate(18.669 0)"
                        >
                          <path
                            id="Path_33849"
                            data-name="Path 33849"
                            d="M224.966,0c-.269,0-.535.012-.8.034a9.333,9.333,0,0,1,8.608,9.249,9.333,9.333,0,0,1-8.608,9.249c.263.022.53.034.8.034a9.346,9.346,0,0,0,9.407-9.283A9.345,9.345,0,0,0,224.966,0Z"
                            transform="translate(-224.167 0)"
                            fill={name == "stream" ? "#de1b22" : "#333"}
                          />
                        </g>
                        <g id="Group_12607" data-name="Group 12607">
                          <ellipse
                            id="Ellipse_22"
                            data-name="Ellipse 22"
                            cx="1.977"
                            cy="1.292"
                            rx="1.977"
                            ry="1.292"
                            transform="matrix(0.906, -0.423, 0.423, 0.906, 25.63, 16.996)"
                            fill={name == "stream" ? "#de1b22" : "#828282"}
                          />
                          <path
                            id="Path_33850"
                            data-name="Path 33850"
                            d="M268.568,227.74a.277.277,0,0,0-.344-.269l-6.607,1.631a.277.277,0,0,0-.211.269v2.73h0v3.943a2.659,2.659,0,0,0-1.289.262c-.989.461-1.547,1.36-1.245,2.007s1.348.8,2.337.336a2.055,2.055,0,0,0,1.327-1.632h0v-5.2l6.031-1.489Z"
                            transform="translate(-238.729 -218.556)"
                            fill={name == "stream" ? "#de1b22" : "#828282"}
                          />
                          <g
                            id="Group_12606"
                            data-name="Group 12606"
                            transform="translate(26.838 8.907)"
                          >
                            <path
                              id="Path_33851"
                              data-name="Path 33851"
                              d="M471.714,401.339h0Z"
                              transform="translate(-469.919 -394.34)"
                              fill={name == "stream" ? "#de1b22" : "#828282"}
                            />
                            <path
                              id="Path_33852"
                              data-name="Path 33852"
                              d="M429.783,227.47l-.787.194v6.771h0v.927h0a2.017,2.017,0,0,1-1.325,1.677,2.967,2.967,0,0,1-.543.192,2.423,2.423,0,0,0,1.674-.192,2.048,2.048,0,0,0,1.326-1.64h0v-7.66A.277.277,0,0,0,429.783,227.47Z"
                              transform="translate(-427.125 -227.462)"
                              fill={name == "stream" ? "#de1b22" : "#828282"}
                            />
                          </g>
                        </g>
                      </g>
                    </svg>
                    Stream Mixes
                  </Link>
                </li>
                <li className={`nav-item`}>
                  <Link
                    to={"/events"}
                    className="nav-link"
                    style={{
                      color: name == "events" ? "#de1b22" : "gray",
                    }}
                  >
                    <svg
                      width="23.346"
                      height="19.092"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21.5994 20.746L20.2252 5.27117C20.1958 4.92761 19.9062 4.66748 19.5675 4.66748H16.7405C16.7013 2.08589 14.5909 0 11.9994 0C9.40803 0 7.2976 2.08589 7.25833 4.66748H4.43134C4.08778 4.66748 3.80312 4.92761 3.77367 5.27117L2.39944 20.746C2.39944 20.7656 2.39453 20.7853 2.39453 20.8049C2.39453 22.5669 4.00926 24 5.99699 24H18.0019C19.9896 24 21.6043 22.5669 21.6043 20.8049C21.6043 20.7853 21.6043 20.7656 21.5994 20.746ZM11.9994 1.32515C13.8596 1.32515 15.3761 2.81718 15.4154 4.66748H8.58349C8.62275 2.81718 10.1393 1.32515 11.9994 1.32515ZM18.0019 22.6748H5.99699C4.75036 22.6748 3.73932 21.8503 3.71968 20.8344L5.02552 5.98805H7.24393V8.00032C7.24393 8.36841 7.53841 8.66289 7.9065 8.66289C8.2746 8.66289 8.56908 8.36841 8.56908 8.00032V5.98805H15.4059V8.00032C15.4059 8.36841 15.7004 8.66289 16.0685 8.66289C16.4366 8.66289 16.731 8.36841 16.731 8.00032V5.98805H18.9495L20.2792 20.8344C20.2596 21.8503 19.2436 22.6748 18.0019 22.6748Z"
                        fill={name == "events" ? "#de1b22" : "#828282"}
                      />
                    </svg>
                    Cfader Marketplace
                  </Link>
                </li>
                <li
                  className={`nav-item ${name == "ecommerce" ? "active" : ""}`}
                >
                  <a
                     to="#"
                     onClick={openLink}
                    className="nav-link"
                    style={{
                      color: name == "ecommerce" ? "#de1b22" : "gray",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="23.346"
                      height="19.092"
                      viewBox="0 0 23.346 19.092"
                    >
                      <g id="shopping-cart" transform="translate(0 -46.002)">
                        <circle
                          id="Ellipse_1383"
                          data-name="Ellipse 1383"
                          cx="2"
                          cy="2"
                          r="2"
                          transform="translate(6.979 61.094)"
                          fill={name == "ecommerce" ? "#de1b22" : "gray"}
                        />
                        <circle
                          id="Ellipse_1384"
                          data-name="Ellipse 1384"
                          cx="2"
                          cy="2"
                          r="2"
                          transform="translate(15.491 61.094)"
                          fill={name == "ecommerce" ? "#de1b22" : "gray"}
                        />
                        <path
                          id="Path_33922"
                          data-name="Path 33922"
                          d="M.684,47.344H3.63L6.91,58.932a.683.683,0,0,0,.659.492H19.881a.683.683,0,0,0,.658-.487l2.781-9.4a.663.663,0,0,0-.112-.589.691.691,0,0,0-.546-.267H5.429l-.62-2.193A.683.683,0,0,0,4.149,46H.684a.671.671,0,1,0,0,1.342Zm16.461,9.4h-6.84a.671.671,0,1,1,0-1.342h6.84a.671.671,0,1,1,0,1.342ZM8.937,52.713h9.576a.671.671,0,1,1,0,1.342H8.937a.671.671,0,1,1,0-1.342Z"
                          fill={name == "ecommerce" ? "#de1b22" : "gray"}
                        />
                      </g>
                    </svg>
                    Cfader Swag
                  </a>
                </li>
                <li
                  className={`nav-item ${name == "settings" ? "active" : ""}`}
                >
                  <Link
                    to={"/settings"}
                    className="nav-link"
                    style={{
                      color: name == "settings" ? "#de1b22" : "gray",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18.865"
                      height="18.866"
                      viewBox="0 0 18.865 18.866"
                    >
                      <path
                        id="settings"
                        d="M17.831,7.385,16.353,7.2a7.429,7.429,0,0,0-.447-1.08l.913-1.175a1.168,1.168,0,0,0-.1-1.556L15.484,2.148a1.172,1.172,0,0,0-1.56-.1L12.75,2.96a7.4,7.4,0,0,0-1.081-.447l-.188-1.475A1.177,1.177,0,0,0,10.314,0H8.553A1.177,1.177,0,0,0,7.385,1.035L7.2,2.513a7.316,7.316,0,0,0-1.081.447L4.941,2.048a1.169,1.169,0,0,0-1.556.1L2.148,3.381a1.172,1.172,0,0,0-.1,1.561L2.96,6.117A7.342,7.342,0,0,0,2.513,7.2l-1.475.188A1.177,1.177,0,0,0,0,8.553v1.761a1.177,1.177,0,0,0,1.035,1.168l1.478.188a7.429,7.429,0,0,0,.447,1.08l-.913,1.175a1.168,1.168,0,0,0,.1,1.556l1.238,1.238a1.173,1.173,0,0,0,1.56.1l1.174-.913a7.257,7.257,0,0,0,1.08.447l.188,1.475a1.177,1.177,0,0,0,1.168,1.039h1.761a1.177,1.177,0,0,0,1.168-1.035l.188-1.478a7.429,7.429,0,0,0,1.08-.447l1.175.913a1.168,1.168,0,0,0,1.556-.1l1.238-1.238a1.171,1.171,0,0,0,.1-1.56l-.913-1.174a7.258,7.258,0,0,0,.447-1.08l1.475-.188a1.177,1.177,0,0,0,1.038-1.168V8.553a1.176,1.176,0,0,0-1.034-1.168Zm-8.4,5.979a3.93,3.93,0,1,1,3.93-3.93,3.935,3.935,0,0,1-3.93,3.93Z"
                        fill={name == "settings" ? "#de1b22" : "gray"}
                      />
                    </svg>
                    Settings
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default FooterComp;
