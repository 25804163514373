import React from "react";
import { useSelector } from "react-redux";

const SubscriptionBox = ({
  planName,
  price,
  cardRequired,
  commercials,
  soundQuality,
  mixesAccess,
  deviceAtATime,
  subscription,
  selected,
}) => {
  return (
    <div>
      <div className="subscription active" style={{ border: `${selected}` }}>
        <input type="radio" name="pricing" id="card1" />
        <div className="subscription-body">
          <div className="text-center p-3">
            <h5 className="card-title">{planName}</h5>
            <p>{price || ""}</p>
          </div>
        </div>
        <ul className="list-group list-group-flush">
          <li className="list-group-item border-bottom-0">{cardRequired}</li>
          <li className="list-group-item border-bottom-0">{commercials}</li>
          <li className="list-group-item border-bottom-0">{soundQuality}</li>
          {mixesAccess && (
            <li className="list-group-item border-bottom-0">{mixesAccess}</li>
          )}
          <li className="list-group-item border-bottom-0">{deviceAtATime}</li>
          <li className="list-group-item border-bottom-0">{subscription}</li>
        </ul>
      </div>
    </div>
  );
};

export default SubscriptionBox;
