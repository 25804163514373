// eslint-disable-next-line
// helper fucntions
import SecureLS from 'secure-ls';
import * as $ from "jquery";

var ls = new SecureLS();

export const loop3 = [1, 2, 3];
export const loop4 = [1, 2, 3, 4];
export const loop5 = [1, 2, 3, 4, 5];
export const loop8 = [1, 2, 3, 4, 5, 6, 7, 8];
export const loop10 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

// set data in Local storage in encrypt mode
export function setToLocalStorage(key, data) {
  ls.set(key, data);
}

// get data from local storage
export function getFromLocalStorage(key) {
  let dataFromStorage = ls.get(key);
  return dataFromStorage;
}

// remove data from local storage
export function removeFromLocalStorage(key) {
  ls.remove(key);
}

// remove all keys from storage
export function removeAllFromLocalStorage() {
  ls.removeAll();
}

// convert file into url
export const convertFileIntoUrl = async (fileObj, setState, type, dataState, id) => {
  let URL = window.URL || window.webkitURL;
  if (type === 'video') {
    let urlBlob = URL.createObjectURL(fileObj);
    let urlArr = [...dataState];
    urlArr.push({ url: urlBlob, id: id });
    setState(urlArr);
  } else {
    let urlBlob = await URL.createObjectURL(fileObj);
    setState(urlBlob);
  }
};

export function scrollFunction() {
  if (
    document.body.scrollTop > 80 ||
    document.documentElement.scrollTop > 80
  ) {
    $(".navbar").addClass("fixed-top");
  } else {
    $(".navbar").removeClass("fixed-top");
  }
}
