// import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
// import {useDispatch, useSelector} from 'react-redux';
// import {getAllMixes} from '../../api/song';
// import FooterComp from '../Main/FooterComp';
// import Wrapper from '../Main/Wrapper';
// import {LogoRadio} from '../../assets/images';
// import FreeUserModal from '../../components/Modals/FreeUserPopup';
// import ReactStars from 'react-rating-stars-component';
// import RatingModal from '../../components/Modals/RatingModal';
// import {getMixList} from '../../reducers/songSlice';
// import {useNavigate} from 'react-router-dom';
// import UpgradeToPremium from '../../components/Modals/UpgradeToPremium';
// import { toast } from 'react-toastify';
// import useAdsIntegration from '../../hooks/useAdsIntegration';
// import { checkMusicAnotherComponent } from '../../reducers/checkSlice';

// const Stream = ({isPlaying,setIsPlaying,musicPlay,musicPause,timeoutId,innerVal}) => {  
//   const dispatch = useDispatch();
//   const {mixes, mixList,isMusicPlay} = useSelector(state => state.songs);
//   const {user,isPlayingData} = useSelector(state => state.authUser);
//   const {check } = useSelector((state) => state.check);

//   const [data, setData] = useState({});
//   const [checked, setChecked] = useState(null);
//   const [isLoading, setIsLoading] = useState(true);
//   const navigate = useNavigate();



//   useEffect(() => {
//     dispatch(getAllMixes()).then(res => {
//       if (res.statusCode === 200) {
//         setIsLoading(false)
//       } else {
//         toast.error("Error!!")
//       }
//     }).catch(err => {
//       setIsLoading(false)
//       toast.error(err)
//     })
//   }, []);

//   const toggle = (evt, data) => {
//     setChecked(current =>
//       current === evt.target.value ? null : evt.target.value,
//     );
//     if (evt.target.checked) {
//       dispatch(getMixList(data.songs));
//     } else {
//       dispatch(getMixList({}));
//     }
//   };

//   const fmtMSS = s => {
//     const data = (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s;
//     return parseFloat(data).toFixed(2);
//   };

//   return (
//     <>
//       <Wrapper>
//         <section id="sign-up-new">
//           <div className="container">
//             {user?.subscription?.type === 'premium' ||
//             user?.subscription?.type === 'freeTrial' ? (
//               <>
//                 <div className="row align-items-center justify-content-center mt-sm-0 mt-md-5 mt-lg-5">
//                   <div className="col-sm-12 col-md-7 col-lg-5 mt-sm-0 mt-md-5 mt-lg-5">
//                     {isLoading ? (
//                      <div className='w-100 d-flex justify-content-center'>
//                        <div class="spinner-border text-danger" role="status">
//                         <span class="sr-only">Loading...</span>
//                       </div>
//                      </div>
//                     ) : (
//                       <div className="stream main">
//                         <h1>Stream Mixes</h1>
//                         <ul className="list-group">
//                           {mixes?.mixes?.length > 0 &&
//                             mixes?.mixes?.map(v => (
//                               <li
//                                 key={v._id}
//                                 className="list-group-item d-flex justify-content-between align-items-center border-bottom"
//                                 style={{backgroundColor: 'rgba(0, 0, 0, 0 )'}}>
//                                 <div className="d-flex align-items-center">
//                                   <input
//                                     className="form-check-input mx-2"
//                                     type="checkbox"
//                                     value={v._id}
//                                     checked={checked === v._id}
//                                     onChange={e => toggle(e, v)}
//                                   />

//                                   <div className="img-video">
//                                     <img
//                                       style={{
//                                         width: '70px',
//                                         height: '70px',
//                                         objectFit: 'contain',
//                                       }}
//                                       src={v?.image || LogoRadio}
//                                     />
//                                   </div>
//                                   <div className="video_cont mx-3">
//                                     <span>{v.name}</span>
//                                     <div className="star-rating d-flex justify-content-center">
//                                       <ReactStars
//                                         count={5}
//                                         size={16}
//                                         activeColor="#ffd700"
//                                         isHalf={true}
//                                         value={v.rating}
//                                         edit={false}
//                                       />
//                                       <div className="ratings mx-2">
//                                         <a
//                                           role="button"
//                                           onClick={() => setData(v)}
//                                           className="rate-now text-decoration-underline"
//                                           data-bs-toggle="modal"
//                                           data-bs-target="#rate-popup">
//                                           Rate Now
//                                         </a>
//                                       </div>
//                                     </div>
//                                   </div>
//                                 </div>
//                                 <div className="times">
//                                   <span className="badge">
//                                     {fmtMSS(v.duration)}
//                                   </span>
//                                 </div>
//                               </li>
//                             ))}
//                           <div className="times">
//                             <span className="badge">
//                               <a
//                                 role={'button'}
//                                 onClick={() => Object.keys(mixList).length ? navigate('/main') : toast.error("Select at least one mixe")} 
//                                 className="login_btn d-md-flex d-lg-flex justify-content-right">
//                                 Play
//                               </a>
//                             </span>
//                           </div>
//                         </ul>
//                       </div>
//                     )}
//                   </div>
//                 </div>
//                 <RatingModal data={data} />
//               </>
//             ) : (
//               <FreeUserModal />
//             )}
//             <UpgradeToPremium />
//             <div className="mt-4">
//               <FooterComp name={'stream'} />
//             </div>
//           </div>
//         </section>
//       </Wrapper>
//     </>
//   );
// };

// export default Stream;
// import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { getAllMixes } from '../../api/song';
// import FooterComp from '../Main/FooterComp';
// import Wrapper from '../Main/Wrapper';
// import { LogoRadio } from '../../assets/images';
// import FreeUserModal from '../../components/Modals/FreeUserPopup';
// import ReactStars from 'react-rating-stars-component';
// import RatingModal from '../../components/Modals/RatingModal';
// import { getMixList } from '../../reducers/songSlice';
// import { useNavigate } from 'react-router-dom';
// import UpgradeToPremium from '../../components/Modals/UpgradeToPremium';
// import { toast } from 'react-toastify';
// import useAdsIntegration from '../../hooks/useAdsIntegration';
// import { checkMusicAnotherComponent } from '../../reducers/checkSlice';

// const Stream = ({
//   isPlaying,
//   setIsPlaying,
//   musicPlay,
//   musicPause,
//   timeoutId,
//   innerVal
// }) => {
//   const dispatch = useDispatch();
//   const { mixes, mixList, isMusicPlay } = useSelector(state => state.songs);
//   const { user, isPlayingData } = useSelector(state => state.authUser);
//   const { check } = useSelector(state => state.check);

//   const [data, setData] = useState({});
//   const [checked, setChecked] = useState(null);
//   const [isLoading, setIsLoading] = useState(true);
//   const navigate = useNavigate();

//   useEffect(() => {
//     dispatch(getAllMixes())
//       .then(res => {
//         if (res.statusCode === 200) {
//           setIsLoading(false);
//         } else {
//           toast.error('Error!!');
//         }
//       })
//       .catch(err => {
//         setIsLoading(false);
//         toast.error(err);
//       });
//   }, []);

//   const toggle = (evt, data) => {
//     setChecked(current =>
//       current === evt.target.value ? null : evt.target.value
//     );
//     if (evt.target.checked) {
//       dispatch(getMixList(data.songs));
//     } else {
//       dispatch(getMixList({}));
//     }
//   };

//   const fmtMSS = s => {
//     const data = (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s;
//     return parseFloat(data).toFixed(2);
//   };

//   const handlePlayMixes = () => {
//     if (Object.keys(mixList).length > 0) {
//       // Play the selected mixes
//       navigate('/main');
//     } else {
//       toast.error('Select at least one mix');
//     }
//   };

//   const handlePlayLiveMusic = () => {
//     // Add your logic to play live music here
//     // You can use the necessary functions from the 'musicPlay', 'musicPause', and 'timeoutId' props
//   };

//   return (
//     <>
//       <Wrapper>
//         <section id="sign-up-new">
//           <div className="container">
//             {user?.subscription?.type === 'premium' ||
//             user?.subscription?.type === 'freeTrial' ? (
//               <>
//                 <div className="row align-items-center justify-content-center mt-sm-0 mt-md-5 mt-lg-5">
//                   <div className="col-sm-12 col-md-7 col-lg-5 mt-sm-0 mt-md-5 mt-lg-5">
//                     {isLoading ? (
//                       <div className="w-100 d-flex justify-content-center">
//                         <div className="spinner-border text-danger" role="status">
//                           <span className="sr-only">Loading...</span>
//                         </div>
//                       </div>
//                     ) : (
//                       <div className="stream main">
//                         <h1>Stream Mixes</h1>
//                         <ul className="list-group">
//                           {mixes?.mixes?.length > 0 &&
//                             mixes?.mixes?.map(v => (
//                               <li
//                                 key={v._id}
//                                 className="list-group-item d-flex justify-content-between align-items-center border-bottom"
//                                 style={{ backgroundColor: 'rgba(0, 0, 0, 0 )' }}
//                               >
//                                 <div className="d-flex align-items-center">
//                                   <input
//                                     className="form-check-input mx-2"
//                                     type="checkbox"
//                                     value={v._id}
//                                     checked={checked === v._id}
//                                     onChange={e => toggle(e, v)}
//                                   />

//                                   <div className="img-video">
//                                     <img
//                                       style={{
//                                         width: '70px',
//                                         height: '70px',
//                                         objectFit: 'contain'
//                                       }}
//                                       src={v?.image || LogoRadio}
//                                     />
//                                   </div>
//                                   <div className="video_cont mx-3">
//                                     <span>{v.name}</span>
//                                     <div className="star-rating d-flex justify-content-center">
//                                       <ReactStars
//                                         count={5}
//                                         size={16}
//                                         activeColor="#ffd700"
//                                         isHalf={true}
//                                         value={v.rating}
//                                         edit={false}
//                                       />
//                                       <div className="ratings mx-2">
//                                         <a
//                                           role="button"
//                                           onClick={() => setData(v)}
//                                           className="rate-now text-decoration-underline"
//                                           data-bs-toggle="modal"
//                                           data-bs-target="#rate-popup"
//                                         >
//                                           Rate Now
//                                         </a>
//                                       </div>
//                                     </div>
//                                   </div>
//                                 </div>
//                                 <div className="times">
//                                   <span className="badge">
//                                     {fmtMSS(v.duration)}
//                                   </span>
//                                 </div>
//                               </li>
//                             ))}
//                           <div className="times">
//                             <span className="badge">
//                               <a
//                                 role={'button'}
//                                 onClick={handlePlayMixes}
//                                 className="login_btn d-md-flex d-lg-flex justify-content-right"
//                               >
//                                 Play
//                               </a>
//                             </span>
//                           </div>
//                         </ul>
//                       </div>
//                     )}
//                   </div>
//                 </div>
//                 <RatingModal data={data} />
//               </>
//             ) : (
//               <FreeUserModal />
//             )}
//             <UpgradeToPremium />
//             <div className="mt-4">
//               <FooterComp name={'stream'} />
//             </div>
//           </div>
//         </section>
//       </Wrapper>
//     </>
//   );
// };

// export default Stream;


import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllMixes } from '../../api/song';
import FooterComp from '../Main/FooterComp';
import Wrapper from '../Main/Wrapper';
import { LogoRadio } from '../../assets/images';
import FreeUserModal from '../../components/Modals/FreeUserPopup';
import ReactStars from 'react-rating-stars-component';
import RatingModal from '../../components/Modals/RatingModal';
import { getMixList } from '../../reducers/songSlice';
import { useNavigate } from 'react-router-dom';
import UpgradeToPremium from '../../components/Modals/UpgradeToPremium';
import { toast } from 'react-toastify';
import useAdsIntegration from '../../hooks/useAdsIntegration';
import { checkMusicAnotherComponent } from '../../reducers/checkSlice';

const Stream = ({
  isPlaying,
  setIsPlaying,
  musicPlay,
  musicPause,
  timeoutId,
  innerVal
}) => {
  const dispatch = useDispatch();
  const { mixes, mixList, isMusicPlay } = useSelector(state => state.songs);
  const { user, isPlayingData } = useSelector(state => state.authUser);
  const { user:updatedUser } = useSelector((state) => state?.updatedUserProfile?.updatedUserProfile)
  const { check } = useSelector(state => state.check);

  const [data, setData] = useState({});
  const [checked, setChecked] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
 
  // Initialize ad integration
  useAdsIntegration();

  useEffect(() => {
    dispatch(getAllMixes())
      .then(res => {
        if (res.statusCode === 200) {
          setIsLoading(false);
        } else {
          toast.error('Error!!');
        }
      })
      .catch(err => {
        setIsLoading(false);
        toast.error(err);
      });
  }, []);



  const toggle = (evt, data) => {
    setChecked(current =>
      current === evt.target.value ? null : evt.target.value
    );
    if (evt.target.checked) {
      dispatch(getMixList(data.songs));
    } else {
      dispatch(getMixList({}));
    }
  };

  const fmtMSS = s => {
    const data = (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s;
    return parseFloat(data).toFixed(2);
  };

  const handlePlayMixes = () => {
    if (Object.keys(mixList).length > 0) {
      // Play the selected mixes
      musicPause()
      navigate('/main');
    } else {
      toast.error('Select at least one mix');
    }
  };

  return (
    <>
      <Wrapper>
        <section id="sign-up-new">
          <div className="container">
            {user?.subscription?.type === 'premium' || updatedUser?.subscription?.type ==="premium" ||
            user?.subscription?.type === 'freeTrial' ? (
              <>
                <div className="row align-items-center justify-content-center mt-sm-0 mt-md-5 mt-lg-5">
                  <div className="col-sm-12 col-md-7 col-lg-5 mt-sm-0 mt-md-5 mt-lg-5">
                    {isLoading ? (
                      <div className="w-100 d-flex justify-content-center">
                         <div className="spinner-border text-danger" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      <div className="stream main">
                        <h1>Stream Mixes</h1>
                        <ul className="list-group">
                          {mixes?.mixes?.length > 0 &&
                            mixes?.mixes?.map(v => (
                              <li
                                key={v._id}
                                className="list-group-item d-flex justify-content-between align-items-center border-bottom"
                                style={{ backgroundColor: 'rgba(0, 0, 0, 0 )' }}
                              >
                                <div className="d-flex align-items-center ">
                                  <div className='me-3 mb-2 pb-1'>
                                    {checked === v._id ? null : <small className='d-block text-muted'>select</small> }
                                  
                                  <input
                                    className="form-check-input mx-2"
                                    type="checkbox"
                                    value={v._id}
                                    checked={checked === v._id}
                                    onChange={e => toggle(e, v)}
                                  />
                                  </div>

                                  <div className="img-video">
                                    <img
                                      style={{
                                        width: '70px',
                                        height: '70px',
                                        objectFit: 'contain'
                                      }}
                                      src={v?.image || LogoRadio}
                                    />
                                  </div>
                                  <div className="video_cont mx-3">
                                    <span>{v.name}</span>
                                    <div className="star-rating d-flex justify-content-center">
                                      <ReactStars
                                        count={5}
                                        size={16}
                                        activeColor="#ffd700"
                                        isHalf={true}
                                        value={v.rating}
                                        edit={false}
                                      />
                                      <div className="ratings mx-2">
                                        <a
                                          role="button"
                                          onClick={() => setData(v)}
                                          className="rate-now text-decoration-underline"
                                          data-bs-toggle="modal"
                                          data-bs-target="#rate-popup"
                                        >
                                          Rate Now
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="times">
                                  <span className="badge">
                                    {fmtMSS(v.duration)}
                                  </span>
                                </div>
                              </li>
                            ))}
                          <div className="times">
                            <span className="badge">
                              <a
                                role={'button'}
                                onClick={handlePlayMixes}
                                className="login_btn d-md-flex d-lg-flex justify-content-right"
                              >
                                Play
                              </a>
                            </span>
                          </div>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
                <RatingModal data={data} />
              </>
            ) : (
              <FreeUserModal />
            )}
            <UpgradeToPremium />
            <div className="mt-4">
              <FooterComp name={'stream'} />
            </div>
          </div>
        </section>
      </Wrapper>
    </>
  );
};

export default Stream;
