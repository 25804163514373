import React from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { contactToAdmin } from "../../api/user";
import { ArrowLeft, ContactIcon } from "../../assets/images";
import Wrapper from "../Main/Wrapper";

const ContactForm = () => {
  const dispatch = useDispatch();
  const {user} = useSelector(state => state.authUser)

  // use-hook-form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();


  useEffect(() => {
    let defaultValues = {};
    if (user) {
      defaultValues.name = `${user?.firstName} ${ user?.lastName}`
      defaultValues.email = user?.email;
      reset({...defaultValues});
    }
  }, [user]);


  const onSubmit = (data) => {
    const apiData = {
      name: data.name,
      contact: data.email,
      subject: data.subject,
      message: data.message,
    };
    dispatch(contactToAdmin(apiData))
      .then((res) => {
        if (res.statusCode === 200) {
          toast.success(res.message);
          reset();
        } else {
          toast.error("something went wrong");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err);
      });
  };

  return (
    <Wrapper>
      <section id="sign-up-new">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-sm-12 col-md-7 col-lg-5">
              <div className="abouts">
                <div className="d-flex">
                  <Link to={"/settings"}>
                    <img src={ArrowLeft} />
                  </Link>
                  <h1 className="mx-3 mb-3">Contact Us</h1>
                </div>
                <div className="cont_logo text-center mx-auto mx-3">
                  <img src={ContactIcon} />
                </div>
                <form className="conatct_fm">
                  <div className="mb-4 input_ps">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      name="name"
                      {...register("name", { required: true })}
                    />
                    {errors.name && (
                      <span className="text-danger">Name is required</span>
                    )}
                  </div>
                  <div className="mb-4 input_ps">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email Address"
                      name="email"
                      {...register("email", { required: true })}
                    />
                    {errors.email && (
                      <span className="text-danger">Email is required</span>
                    )}
                  </div>
                  <div className="mb-4 input_ps">
                    <select
                      className="form-select"
                      //    className="form-control"
                      placeholder="Select Country"
                      name="subject"
                      {...register("subject", { required: true })}
                    >
                      <option selected value={'General Message'}>General Message</option>
                      <option value="Radio Support">Radio Support</option>
                      <option value="App Support">App Support</option>
                      <option value="Subscription Support">Subscription Support</option>
                      <option value="Advertising Inquiry">Advertising Inquiry</option>
                      <option value="Other">Other</option>
                    </select>
                    {errors.subject && (
                      <span className="text-danger">Country is required</span>
                    )}
                  </div>
                  <div className="mb-4 input_ps">
                    <textarea
                      className="form-control"
                      rows="3"
                      placeholder="Message"
                      name="message"
                      {...register("message", { required: true })}
                    />
                    {errors.message && (
                      <span className="text-danger">Message is required</span>
                    )}
                  </div>
                  <div className="text-center">
                    <a role={'button'}
                      className="main_btn mx-auto text-center mt-3"
                      onClick={handleSubmit(onSubmit)}
                    >
                      Submit
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Wrapper>
  );
};

export default ContactForm;
