import React, { useEffect,useLayoutEffect, useRef } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { getEvents } from "../../api/user";
import { EventImg, PlayIcon, TopLogo } from "../../assets/images";
import FooterComp from "../Main/FooterComp";
import useAdsIntegration from "../../hooks/useAdsIntegration";
import { checkMusic } from "../../reducers/userSlice";
import { checkMusicAnotherComponent } from "../../reducers/checkSlice";


const Events = ({globalRef,isPlaying,setIsPlaying,musicPlay,musicPause,timeoutId,innerVal}) => {
  const { events } = useSelector((state) => state.authUser);
  const { isplayingData } = useSelector((state) => state.isPlayingData);
  const {check } = useSelector((state) => state.check);

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true)
  const [loading, setLoading] = useState(false);



  useEffect(() => {
    dispatch(getEvents()).then(res => {
      if (res.statusCode === 200) {
        setIsLoading(false)
      } else {
        toast.error("Error!!")
      }
    }).catch(err => {
      setIsLoading(false)
      toast.error(err)
    })
  }, []);

  return (
    <div style={{ backgroundColor: "#111111", minHeight:'100vh' }}>
      <header id="home" className="mb-4">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 main_menu">
              <nav
                id="navbar"
                className="navbar navbar-expand-lg navbar-light bg-none custom_navbar py-3"
              >
                <div className="container-fluid px-0">
                  <Link to={"/"} className="navbar-brand" >
                    <img src={TopLogo} alt="Logo" />
                  </Link>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
    
        <section id="sign-up-new">
        {isLoading ? (
         <div class="spinner-border text-danger" role="status">
         <span class="sr-only">Loading...</span>
       </div>
      ) : (
        <div className="container">
          <div className="row align-items-center justify-content-center mt-4">
            <div className="col-sm-12 col-md-7 col-lg-5">
              <div
                className="stream main image_event p-4 mt-5 mb-4 "
                style={{ backgroundColor: "#0F0F0F" }}
              >
                <h1>Cfader Marketplace</h1>
                {events?.events?.length > 0 &&
                  events?.events?.map((item) => (
                    <div key={item._id}>
                      <div className="new-stream">
                        <div className="event-block">
                          <img
                            src={item.eventImage || EventImg}
                            alt="Nature"
                            style={{ width: "100%", height:200 }}
                          />
                          <div className="event-detail d-flex justify-content-between">
                            <h4>{item.title}</h4>
                            <p>
                              <img src={PlayIcon} />
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="event_info p-2">
                        <p>{item.description}</p>
                      </div>
                    </div>
                  ))}
              </div>
            </div>

            <div>
    </div>

          </div>
          <FooterComp name={'events'} />
        </div>
           )}
      </section>
   

     
    </div>
  );
};

export default Events;
