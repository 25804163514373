import React from "react";
import AboutImg from "../../assets/images/about_img.png"
import RadioSvg from "../../assets/images/radio_icon.svg"
import MusicSvg from "../../assets/images/music_icon.svg"


const About = () => {
  return (
    <section id="aboutus">
      <div className="container">
        <div className="row d-flex align-items-start justify-content-center">
          <div className="col-md-12 col-lg-6">
            <div className="about_img_bg text-center py-5">
              <img
                data-aos="fade-up-right"
                data-aos-duration="1000"
                data-aos-delay="50"
                src={AboutImg}
                alt="img"
                className="img-fluid img_bg_left aos-init aos-animate"
              />
            </div>
          </div>
          <div className="col-md-12 col-lg-6">
            <div className="heading-title">
              <h2>ABOUT US</h2>
              <h1 className="f_34">Listen to your favorite songs everywhere!!</h1>
              <p className="mb-5">
                Cfader Radio is a premium streaming service that exclusively
                features Soulful House Music. Other streaming services have
                ignored this genre for years, that disrespect ends NOW!
              </p>
              <div className="d-sm-block d-md-flex d-lg-flex justify-content-between align-items-center about_shadow px-2 py-4">
                <div className="abount-cnt_img">
                  <img src={RadioSvg} className="mx-3" />
                </div>
                <div className="about_content">
                  <h1 className="f_22">Join the Cfader Radio community</h1>
                  <p>
                    By downloading the App onto your mobile devices or simply
                    listen through the website. Everyone gets a free 30-day
                    premium membership that is commercial free
                  </p>
                </div>
              </div>
              <div className="dd-sm-block d-md-flex d-lg-flex justify-content-between align-items-center about_shadow px-2 py-4 mt-4">
                <div className="abount-cnt_img">
                  <img src={MusicSvg} className="mx-3" />
                </div>
                <div className="about_content">
                  <h1 className="f_22">Our Features</h1>
                  <p>
                    We feature the hottest House Music mixes ever created by
                    many of the hottest DJ’s on the planet. All of these mixes
                    will be available to the premium members on-demand.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
