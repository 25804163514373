import React from "react";
import { Link } from "react-router-dom";
import { TopLogo } from "../../assets/images";

const Wrapper = (props) => {
  return (
    <>
      <div style={{ backgroundColor: "#111111" }}>
        <header id="home" className="mb-4">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 main_menu">
                <nav
                  id="navbar"
                  className="navbar navbar-expand-lg navbar-light bg-none custom_navbar py-3"
                >
                  <div className="container-fluid px-0">
                    <Link to={'/'} className="navbar-brand" >
                      <img src={TopLogo} alt="Logo" />
                    </Link>
                    {/* <button
                      className="navbar-toggler"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarSupportedContent"
                      aria-controls="navbarSupportedContent"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <span className="navbar-toggler-icon"></span>
                    </button> */}
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </header>
        {props.children}
      </div>
    </>
  );
};

export default Wrapper;
