import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getTermsPolicy } from "../../api/user";
import { ArrowLeft } from "../../assets/images";
import Wrapper from "../Main/Wrapper";

const Privacy = () => {
    const { termspolicy } = useSelector((state) => state.staticData);
    const navigate = useNavigate()

    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(getTermsPolicy("privacypolicy"));
    }, []);
  

  
    return (
      <Wrapper>
        <section id="sign-up-new">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-sm-12 col-md-7 col-lg-5">
                <div className="about">
                  <div className="d-flex">
                    <span  onClick={()=>navigate(-1)}>
                      <img src={ArrowLeft} alt="" />
                    </span>
                    <h1 className="mx-3">Privacy & Policy</h1>
                  </div>
                  <h6>Privacy & Policy of Cfader Radio</h6>
                  <p dangerouslySetInnerHTML={{ __html: termspolicy?.data?.text }}></p>
  
                </div>
              </div>
            </div>
          </div>
        </section>
      </Wrapper>
    );
}

export default Privacy