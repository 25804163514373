import React from 'react';
import { useNavigate } from 'react-router-dom';
import Wrapper from '../Main/Wrapper';

const NotFound404 = () => {
    const navigate = useNavigate();
  return (
    <Wrapper>
      <div
        className="container"
        style={{
          backgroundColor: 'black',
          width: '100%',
          height: '100vh',
        }}>
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <div>
            <div
              style={{
                color: 'white',
                fontSize: '50px',
                fontWeight: 'bold',
              }}>
              {' '}
              Page Not Found...
            </div>
            <button
            onClick={() => navigate('/')}
              style={{
                color: 'white',
                fontSize: '20px',
                fontWeight: 'bold',
                backgroundColor: 'red',
                borderRadius: '5px',
                border: 'none',
                padding: '10px',
                alignSelf: 'center',
                width: '100%',
                marginTop: '20px',
              }}>
              Go to Home
            </button>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default NotFound404;
