import { combineReducers } from '@reduxjs/toolkit';
import promoSlice from './promoSlice';
import songSlice from './songSlice';
import staticDataSlice from './staticDataSlice';
import authUser from './userSlice';
import isPlayingDataSlice from './isPlayingDataSlice';
import checkSlice from './checkSlice';
import updatedProfile from './updateUserProfileSlice'

const rootReducer = combineReducers({
  authUser,
  isPlayingData:isPlayingDataSlice,
  staticData: staticDataSlice,
  songs: songSlice,
  promo: promoSlice,
  check : checkSlice,
  updatedUserProfile : updatedProfile

});

export default rootReducer;
