import React from "react";
import TwiterSvg from "../../assets/images/twiter.svg";
import FbImg from "../../assets/images/facebook.svg";
import InstaImg from "../../assets/images/instagram.svg";
import FooterLogo from "../../assets/images/footer_logo.png"
const Footer = () => {
  return (
    <footer className="section_padding_edit text-white pb-0 pt-5 footer-block">
      <div className="container">
        <div className="row pos-zbox">
          <div className="col-sm-12 col-md-12 text-center">
            <div className="footer_text">
              <img src={FooterLogo} alt="img" className="img-fluid" />
            </div>
            <p className="d-flex align-items-center  justify-content-center text_black text-center mt-4 px-0">
              <a  className="me-3">
                <span>
                  <img src={TwiterSvg} />
                </span>
              </a>
              <a  className="me-3">
                <span>
                  <img src={FbImg} />
                </span>
              </a>
              <a  className="me-3">
                <span>
                  <img src={InstaImg} />
                </span>
              </a>
            </p>
          </div>

          <hr className="my-4" />
          <div className="d-sm-block  d-md-flex d-lg-flex justify-content-between">
            <p className="f_18 f_light">
              © 2022 Crossfader Radio LLC. All Rights Reserved.
            </p>
            <p className="f_18  f_light">
              Developed By{" "}
              <a className="text-white text-decoration-underline" href="https://parastechnologies.com" target="_blank">
                {" "}
                Parastechnologies.com
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
