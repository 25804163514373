import React from "react";
import DownloadAppIcon from "../../assets/images/download-app.png";
import GooglePlayIcon from "../../assets/images/google-play.png";
import AndroidAppIcon from "../../assets/images/android-app.png";
import RightImg from "../../assets/images/download_cfader.png"

const Download = () => {
  return (
    <section id="download">
      <div className="container">
        <div className="row d-flex align-items-start justify-content-center">
          <div className="col-md-12 col-lg-7 mb-4">
            <div className="heading-title">
              <h2>Download the Cfader App Today!</h2>
            </div>
            <ul className="download_cfader pt-4">
              <li>Listen anytime, anywhere.</li>
              <li>Take your house music with you wherever you go.</li>
              <li>
                Listen to Cfader Radio on mobile devices, desktop, TV, Apple
                Watch, or using voice assistants.
              </li>
            </ul>
            <div className="download_btn">
              <a href="#">
                <img src={DownloadAppIcon} className="img-fluid me-1" />
              </a>
              <a href="#">
                <img src={GooglePlayIcon} className="img-fluid me-1" />
              </a>
              <a href="#">
                <img src={AndroidAppIcon} className="img-fluid" />
              </a>
            </div>
          </div>
          <div className="col-md-12 col-lg-5 mt-5">
            <div className="download_img text-center">
              <img src={RightImg} className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Download;
