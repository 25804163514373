
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useRef, useState, useCallback } from "react";
import "./index.css";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Navigation from "./navigation/Navigation";
import { useDispatch, useSelector } from "react-redux";
import { getFromLocalStorage } from "./helpers";
import { loginSuccess } from "./reducers/userSlice";
import Global from "./components/GlobalAudio/Global";
import { getMixList } from "./reducers/songSlice";
import { getAds } from "./api/song";
import { checkMusicAnotherComponent } from "./reducers/checkSlice";
import { getUserProfile } from "./api/user";


function App() {
  // const {pathname} = useLocation()
  const globalRef = useRef(null);
  const {token} = useSelector(state=>state.authUser)
  const dispatch = useDispatch();
  // const token = getFromLocalStorage("token");
  // const user = getFromLocalStorage("userData");
    
  useEffect(() => {
    AOS.init({
      disable: function () {
        var maxWidth = 800;
        return window.innerWidth < maxWidth;
      },
    });
  }, []);

  // useEffect(() => {
  //   if (Object.keys(getFromLocalStorage("userData")).length) {
  //     dispatch(
  //       loginSuccess({
  //         token,
  //         user,
  //       })
  //     );
  //   }
  // }, [token, user]);

  const [adsData, setAdsData] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [innerVal, setInnerVal] = useState(null);
  const [timeData, setTimeData] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);
  const [preUser,setPreUser] = useState("")
  const [pathname, setPathname] = useState(window.location.pathname);
  const { isplayingData } = useSelector((state) => state.isPlayingData);
  const { isMusicPlay,user } = useSelector((state) => state.authUser);
  const { checkSlice } = useSelector((state) => state.check);
  
  const yourAudioRef = useRef(null);
  const adsIntervalRef = useRef();
  const adsTimeOurRef = useRef();
  const yourAudio = yourAudioRef.current;

  // useEffect(()=>{
  //   if(user) {setPreUser(user.subscriptionTypeSelection) }
  // },[user])
  useEffect(() => {
    if (user) {
      if (preUser !== user.subscriptionTypeSelection) {
        // Subscription type changed
        clearInterval(adsIntervalRef.current);
        clearTimeout(adsTimeOurRef.current);
      }
      setPreUser(user.subscriptionTypeSelection);
    }
  }, [user, preUser]);
  

  useEffect(() => {
    if (yourAudio) {
      yourAudio.src = "https://azuracast.cfader.com:8000/radio.mp3";
    }
  }, [yourAudio]);

  const pauseMusic = useCallback(() => {
    setIsPlaying(false);
    dispatch(checkMusicAnotherComponent(false));
    if (yourAudio) {
      yourAudio.pause();
    }
    clearTimeout(timeData);
    clearInterval(adsIntervalRef.current);
    clearTimeout(adsTimeOurRef.current);
    
  }, [yourAudio, timeData, innerVal, timeoutId, dispatch]);


  useEffect(() => {
    const handleTabClose = (event) => {
      event.preventDefault();
      setIsPlaying(false)
      pauseMusic(); // Pause the music when the tab is closed
    };
  
    const handleUnload = () => {
      setIsPlaying(false)
      pauseMusic(); // Pause the music when the tab is unloaded
    };
  
    window.addEventListener('beforeunload', handleTabClose);
    window.addEventListener('unload', handleUnload);
  
    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
      window.removeEventListener('unload', handleUnload);
    };
  }, [pauseMusic,setIsPlaying]);

 

  useEffect(()=>{
    let timeInterval;
    if( pathname !== "/user-verification"  && token &&  user.subscriptionTypeSelection ===  "free")
  timeInterval =    setInterval(() => {
      dispatch(getUserProfile())
    }, 10*1000);

    return () => clearInterval(timeInterval)
  },[token,user,pathname])

  const playMusic = useCallback(() => {
    if (isPlaying) {
      dispatch(getMixList({}));
      setIsPlaying(false);
      pauseMusic()
    } else {
      dispatch(getMixList({}));

      if (yourAudio) {
        yourAudio.play();
      }
      setIsPlaying(true);

      

      if (yourAudio) {
        clearTimeout(adsTimeOurRef.current);
        clearInterval(adsIntervalRef.current);
        const adsAudio = new Audio(); // Create a new audio element for playing ads
          let isAdPlaying = false; 
          if (user && user?.subscriptionTypeSelection === "free") {
          adsIntervalRef.current = setInterval(() => {
            if (!isAdPlaying) {
              dispatch(getAds())
                .then((res) => {
                  setAdsData(res.data.ads.adsFile);
                  adsAudio.src = res.data.ads.adsFile;
                  adsAudio.load();
                  yourAudio.pause()
                  adsAudio.play();
                  isAdPlaying = true; // Mark that an ad is currently playing
                  adsTimeOurRef.current = setTimeout(() => {
                    if (yourAudio) {
                      adsAudio.pause(); // Pause the ad
                      // yourAudio.src = "https://azuracast.cfader.com:8000/radio.mp3";
                      // yourAudio.load();
                      yourAudio.play(); // Resume the original music
                      isAdPlaying = false; // Mark the ad as finished playing
                    }
                  }, 9 * 1000);
                })
                .catch((err) => console.log(err));
            }
          },20 * 60 * 1000);
        }
       
        
        return () => {
        setInnerVal(adsIntervalRef.current)};
      }
    }
  }, [yourAudio, isPlaying, timeoutId, dispatch]);


 

  const toastOptions = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    newestOnTop: false,
    closeOnClick: true,
    rtl: false,
    pauseOnFocusLoss: true,
    draggable: true,
    pauseOnHover: true,
  };
  return (
    <Router>
      <ToastContainer {...toastOptions} />
      <Global yourAudioRef={yourAudioRef} />
      <Navigation  isPlaying={isPlaying} setIsPlaying={setIsPlaying} timeoutId={adsTimeOurRef} innerVal={adsIntervalRef} musicPause={pauseMusic} musicPlay ={playMusic} setPathname={setPathname}  />
    </Router>
  );
}


export default App;