import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mixes: [],
  playlist: null,
  mixList: {},
};

const songSlice = createSlice({
  name: "songSlice",
  initialState,
  reducers: {
    getMixes: (state, action) => {
      state.mixes = action.payload;
    },
    getPlaylist: (state, action) => {
      state.playlist = action.payload;
    },
    getMixList: (state, action) => {
      state.mixList = action.payload;
    },
  },
});

export const { getMixes, getPlaylist, getMixList } = songSlice.actions;

export default songSlice.reducer;
