
import React, { useEffect, useState } from 'react'
import { getAds } from '../api/song';
import { useDispatch, useSelector } from 'react-redux';
import { getMixList } from '../reducers/songSlice';
import { getIsPlaying } from '../reducers/isPlayingDataSlice';
import { checkMusicAnotherComponent } from '../reducers/checkSlice';

const useAdsIntegration = (audioElement) => {
  const [adsData, setAdsData] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [innerVal, setInnerVal] = useState(null);
  const [timeData, setTimeData] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);
  const { isplayingData } = useSelector((state) => state.isPlayingData);
  const {isMusicPlay } = useSelector((state) => state.authUser);
  const {checkSlice } = useSelector((state) => state.check);

  
  const dispatch = useDispatch();

  const yourAudio = document.getElementById("yourAudio");
  let intervalId;
  useEffect(() => {
    if (audioElement) {
      yourAudio.src = "https://azuracast.cfader.com:8000/radio.mp3";
    }
  }, []);


useEffect(() => {
  if ( isplayingData === 0) {
      playMusic();
    return () => clearInterval(timeoutId);
  }
}, [ isplayingData,isMusicPlay]);
useEffect(() => {
  return () => clearTimeout(timeoutId);
}, [timeoutId]);
useEffect(() => {
  return () => clearInterval(intervalId);
}, [intervalId]);

  const playMusic = () => {
    dispatch(checkMusicAnotherComponent(true))
    dispatch(getIsPlaying(1))
    if (isPlaying) {
      dispatch(getMixList({}));
      setIsPlaying(false);  
      yourAudio.pause();
      clearTimeout(timeoutId);
      clearInterval(intervalId);
    } else {
      dispatch(getMixList({}));
      yourAudio.src = "https://azuracast.cfader.com:8000/radio.mp3";
      yourAudio.load()
      yourAudio.pause();
      yourAudio.play();
      setIsPlaying(true);
      if (yourAudio.play()) {
        intervalId = setInterval(() => {
          dispatch(getAds())
            .then((res) => {
              setAdsData(res.data.ads);
              yourAudio.addEventListener('loadedmetadata', () => {
              })
              yourAudio.pause();
              yourAudio.src = res.data.ads.adsFile;
              yourAudio.load()

              yourAudio.play();
              const setTime = setTimeout(() => {
                yourAudio.pause();
                yourAudio.src = "https://azuracast.cfader.com:8000/radio.mp3";
                yourAudio.load()

                yourAudio.play();
              }, 10 * 1000);
              setTimeData(setTime);
              return () => clearTimeout(setTime);
            })
            .catch((err) => console.log(err));
          setTimeoutId(timeoutId);
        },  5 * 60 * 1000);
        setInnerVal(intervalId);
      }
    }
  };

  const pauseMusic = () => {
    setIsPlaying(false);
    dispatch(checkMusicAnotherComponent(false))

    yourAudio.src = "https://azuracast.cfader.com:8000/radio.mp3";
    yourAudio.load();
    yourAudio.pause();
    clearTimeout(timeData);
    clearInterval(innerVal);
    clearTimeout(timeoutId);
  };

  return {
    adsData,
    playMusic,
    pauseMusic,
    yourAudio,
    isPlaying,
    setIsPlaying,
  };
};

export default useAdsIntegration;

