import React from "react";
import "../../assets/css/aos.css";
import "../../assets/css/admin-web.css";
import { InviteIcon, LogoRadio } from "../../assets/images";
import Wrapper from "../Main/Wrapper";
import PlayDemoModal from "../../components/Modals/PlayDemoModal";
import RatingModal from "../../components/Modals/RatingModal";
import InviteModal from "../../components/Modals/InviteModal";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import FreeUserModal from "../../components/Modals/FreeUserPopup";

const DemoPlay = () => {
  const [isPlaying, setIsPlaying] = useState(false);

  const navigate = useNavigate();

  return (
    <Wrapper>
      <section style={{ backgroundColor: "#111111" }}>
        <div className="container ">
          <div className="row">
            <div className="col-md-2 custom-name-box justify-content-start">
              <h5 className="btn_rating" role="button" data-bs-toggle="modal"
                      data-bs-target="#video-popup">Play Demo Video!</h5>
            </div>
          </div>
        </div>
      </section>
      <section id="sign-up-new">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-sm-12 col-md-7 col-lg-5">
              <div className="home-main mt-5 pt-5">
                <div className="radio_logos">
                  <img src={LogoRadio} className="img-fluid" />
                </div>
                {/* <div className="description-rating d-flex justify-content-between mt-5">
                  <div className="ratings">
                    <h1>Despacito</h1>
                    <div className="d-flex align-items-center">
                      <i className="fa text-white px-2 fa-star text-primary"></i>
                      <i className="fa text-white px-2 fa-star"></i>
                      <i className="fa text-white px-2 fa-star"></i>
                      <i className="fa text-white px-2 fa-star"></i>
                      <i className="fa text-white px-2 fa-star"></i>
                      <a
                        href=""
                        className="rate-now"
                        data-bs-toggle="modal"
                        data-bs-target="#rate-popup"
                      >
                        Rate Now
                      </a>
                    </div>
                  </div>
                  <div className="btn_rating">
                    <img src={InviteIcon} />
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#invite-popup"
                    >
                      Invite Friends
                    </a>
                  </div>
                </div> */}
                <div
                  className="mt-4 text-right skip-text"
                  onClick={() => navigate("/login")}
                >
                  Skip
                </div>

                <div className="mb-5" style={{ margin: "0 auto" }}>
                  <video id="video-demo" style={{ display: "none" }}></video>
                </div>
                {/* <div className="text-right">
                  {isPlaying ? (
                    <div className="play-btn">
                      <i
                        className="fa fa-pause"
                        style={{
                          color: "#5c5a5a",
                          fontSize: "2rem",
                        }}
                        onClick={() => setIsPlaying(false)}
                      ></i>
                    </div>
                  ) : (
                    <div
                      className="play-btn"
                      onClick={() => setIsPlaying(true)}
                      data-bs-toggle="modal"
                      data-bs-target="#video-popup"
                    >
                      <i
                        className="fa fa-play"
                        style={{
                          color: "#fff",
                          fontSize: "2rem",
                        }}
                      ></i>
                    </div>
                  )}
                </div> */}
              </div>
            </div>
          </div>
          {/* <FooterComp name={"main"} /> */}
        </div>
      </section>
      <PlayDemoModal />
      {/* <RatingModal /> */}
      <InviteModal />
    </Wrapper>
  );
};

export default DemoPlay;
